import { useContext, useState } from "react";
import Heading from "../common/heading/Heading";
import Input from "../common/input/Input";
import Label from "../common/label/Label";
import Button from "../common/button/Button";
import { useTranslation } from "react-i18next";
import { PrivilegesContext } from "../../context/privilegesContext";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { API_ROUTES } from "../../config";
import useAxios from "../../hooks/useAxios";
import EyeIcon from "../icons/EyeIcon";
import EyeOpenIcon from "../icons/EyeOpenIcon";

const LogInComponent: React.FC = () => {
  const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { fetchData } = useAxios();
  const navigate = useNavigate();
  const { setShouldRenderReports, setRestaurantMenus } =
    useContext(PrivilegesContext);
  const [, setCookie]: any = useCookies(["token", "isLogIn"]);
  const { t } = useTranslation();
  const [inputValues, setInputValues] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
  ) => {
    setInputValues({
      ...inputValues,
      [fieldName]: e.target.value,
    });
  };

  const handleLogin = () => {
    const path = `${API_ROUTES.getToken}`;
    const formData = new URLSearchParams();
    formData.append("userName", inputValues.fullName);
    formData.append("password", inputValues.password);
    formData.append("grant_type", "password");

    const config = {
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      fetchData(path, config).then((res) => {
        if (res.error) {
          setError(t("login.error"));
          console.log(error);
        } else {
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("isLogIn", JSON.stringify(true));
          localStorage.setItem("refreshToken", res.data.refresh_token)
          localStorage.setItem("expires", res.data.expires_in)
          
          const expiresInMilliseconds = res.data.expires_in * 1000;
          const expirationDate = new Date(Date.now() + expiresInMilliseconds);
          const expirationDateUTC = expirationDate.toUTCString();
          document.cookie = `token=${res.data.access_token}; expires=${expirationDateUTC}; path=/`;
          document.cookie = `refresh_token=${res.data.refresh_token}; expires=${expirationDateUTC}; path=/`;
          document.cookie = `expires_date=${expirationDate}; expires=${expirationDate}; path=/`;

          fetchUserPrivileges(res.data.access_token, inputValues.fullName);
        }
      });
    } catch (err) {
      console.log("error fetching token");
      console.log(err);
    }
  };

  const fetchUserPrivileges = (token: string, username: string) => {
    const privilegesUrl = `${API_ROUTES.getUserPrivileges}${username}`;

    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    fetchData(privilegesUrl, config)
      .then((res) => {
        localStorage.setItem("UserMenus", JSON.stringify(res.data.UserMenus));
        localStorage.setItem("priv", JSON.stringify(res.data.UserPrivilegesList));
        setShouldRenderReports(true);
        navigate("/reports/sales");
      })
      .catch((error: any) => {
        console.error("Error fetching user privilages:", error);
      });
  };

  const handleShowPw = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPassword(!showPassword)
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      setShowPassword(false)
      event.preventDefault(); // Prevent the default form submission
      handleLogin();
    }
  };


  return (
    <div className="w-screen flex items-center justify-between">
      <div className="w-1/2 h-screen" >
        <img src="/guestit_white_1.svg" alt="" className="absolute" style={{width: "15%"}}/>
        <img
          src="/signInImg.png"
          alt=""
          className="h-full w-full"
          style={{ objectFit: "cover" }}
        />
        {/* <img src="/guestit_black_1.svg" alt="" className="z-50"/> */}
      </div>
      <div className="w-full  h-1/2 flex justify-center items-center">
        <div className="w-1/2">
          <Heading
            children={t("login.login")}
            classes="h-14 flex items-center justify-center text-lg"
            size="h3"
            color="black"
            align="text-center"
          />

          <form className="p-8 flex flex-col" onKeyUp={handleKeyPress}>
            <Label
              htmlFor=""
              text={`${t("login.username")}*`}
              classes=" text-sm leading-5 text-custom-gray mb-1"
            />
            <Input
              onChange={(e) => handleInputChange(e, "fullName")}
              size=""
              value={inputValues.fullName}
              classes="border border-[#DCDCDC] px-4 py-4 rounded-lg mb-2 bg-transparent"
              type="email"
            />

            <Label
              htmlFor=""
              text="Password*"
              classes="text-sm leading-5 text-custom-gray mb-1"
            />
            <div className="min-w-full relative">

            <Input
              onChange={(e) => handleInputChange(e, "password")}
              size=""
              value={inputValues.password}
              classes="w-full border border-[#DCDCDC] px-4 py-4 rounded-lg mb-2 relative bg-transparent"
              type={showPassword ? "text" : "password"}
              />

              <button className="absolute right-2 top-4 transform" onClick={handleShowPw}>
                <EyeOpenIcon />
              </button>
              </div>
            {error ? (
              <div className="text-red-500 text-sm mt-1 bg-red-100 py-2 px-2 rounded border border-red-500">
                {error}
              </div>
            ) : (
              ""
            )}
          </form>
          <div className="flex justify-between items-center h-24 p-8">
            <p className="text-left text-custom-blue text-sm py-4">
              {/* <a href="/forgotpassword">{t("login.forgotPw")}</a> */}
            </p>

            <Button
              label={t("login.login")}
              onClick={handleLogin}
              classes=" w-full py-3 px-4 rounded-lg"
              type="secondary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInComponent;
