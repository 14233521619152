import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Heading from "../common/heading/Heading";
import Select from "../common/select/Select";
import useChartLabels from "../../hooks/useChartLabels";
import { ChartData } from "./OrderVolumeChart.type";
import { useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const options: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 2,
  scales: {
    x: {
      grid: {
        display: false, // Hide the x-axis grid lines
      },
    },
    y: {
      grid: {
        display: false, // Hide the y-axis grid lines
      },
    },
  },
};

const OrderVolumeChart: React.FC = () => {
  const { t } = useTranslation();
  const [interval, setInterval] = useState("hour");
  const labels = useChartLabels(interval);

  const handleIntervalChange = (selectedInterval: string) => {
    setInterval(selectedInterval);
  };

  const generateChartData = (
    interval: string,
  ): { data: number[]; backgroundColor: string[] } => {
    let data: number[] = [];
    let backgroundColor: string[] = [];

    switch (interval) {
      case "hour":
        data = [100, 30, 60, 70, 10, 50, 80, 25, 34, 100, 20, 90];
        break;
      case "day":
        data = [150, 80, 120, 140, 90, 110, 130];
        break;
      case "week":
        data = [400, 300, 350, 450, 500, 550, 600, 650, 700, 750, 800, 850];
        break;
      default:
        break;
    }

    backgroundColor = data.map((value) => {
      if (value >= 0 && value <= 20) {
        return "#FF692E";
      } else if (value > 20 && value <= 40) {
        return "#FDE272";
      } else if (value > 40 && value <= 95) {
        return "#73E2A3";
      } else {
        return "#7F56D9"; // Default color for values beyond 95
      }
    });

    return { data, backgroundColor };
  };

  const { data: chartData, backgroundColor } = generateChartData(interval);

  const data: ChartData = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: chartData,
        backgroundColor: backgroundColor,
        barThickness: 50,
        categorySpacing: 0,
        borderRadius: [10, 10],
        datalabels: {
          display: false,
        },
      },
    ],
  };

  return (
    <Wrapper
      onClick={() => {}}
      classes="min-w-full min-h-full bg-white rounded-xl p-6 col-span-2 border"
    >
      <div className="flex flex-row w-full justify-between">
        <Heading
          children={t("orderVolumeChart.title")}
          align=""
          classes="text-black"
          color=""
          size="h1"
        />
        <Select
          classes="min-w-1/5"
          options={[
            { value: "hour", label: t("orderVolumeChart.perHour") },
            { value: "day", label: t("orderVolumeChart.perDay") },
            { value: "week", label: t("orderVolumeChart.perWeek") },
          ]}
          onChange={handleIntervalChange}
        />
      </div>

      <div className="w-full" style={{ width: "100%", height: "400px" }}>
        <Bar options={options} data={data} className="min-h-full" />
      </div>
    </Wrapper>
  );
};

export default OrderVolumeChart;
