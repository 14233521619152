const TimeIcon = () => {
  const svgIcon = `
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="4" y="4" width="48" height="48" rx="24" fill="#F4EBFF"/>
  <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8"/>
  <path d="M28 25.5V29.5L30.5 31M28 21C23.3056 21 19.5 24.8056 19.5 29.5C19.5 34.1944 23.3056 38 28 38C32.6944 38 36.5 34.1944 36.5 29.5C36.5 24.8056 32.6944 21 28 21ZM28 21V18M26 18H30M36.329 21.592L34.829 20.092L35.579 20.842M19.671 21.592L21.171 20.092L20.421 20.842" stroke="#7F56D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
        
      `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default TimeIcon;
