import React from "react";
import { useTranslation } from "react-i18next";
// import { FoodGradingProps } from './FoodGrading.type'
import Wrapper from "../common/wrapper/Wrapper";
import Heading from "../common/heading/Heading";
import Select from "../common/select/Select";
import TableRow from "./TableRow";
import { TableRowProps } from "./FoodGrading.type";

const tableData: TableRowProps[] = [
  {
    no: 1,
    itemName: "Chocolate Lava",
    category: "Desserts",
    order: 100,
    revenue: 5,
  },
  { no: 2, itemName: "Tiramisu", category: "Desserts", order: 12, revenue: 5 },
  { no: 3, itemName: "Tiramisu", category: "Desserts", order: 23, revenue: 5 },
  { no: 4, itemName: "Apple Pie", category: "Desserts", order: 10, revenue: 5 },
  {
    no: 5,
    itemName: "Chocolate Lava",
    category: "Desserts",
    order: 5,
    revenue: 5,
  },
  { no: 6, itemName: "Tiramisu", category: "Desserts", order: 5, revenue: 5 },
  { no: 7, itemName: "Tiramisu", category: "Desserts", order: 5, revenue: 5 },
  { no: 8, itemName: "Apple Pie", category: "Desserts", order: 5, revenue: 5 },
  { no: 9, itemName: "Tiramisu", category: "Desserts", order: 5, revenue: 5 },
  { no: 10, itemName: "Apple Pie", category: "Desserts", order: 5, revenue: 5 },
];

const FoodGrading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper
      onClick={() => {}}
      classes="bg-white rounded-xl p-6 min-w-full flex flex-col border"
    >
      <div className="flex justify-between items-center">
        <Heading
          children={t("foodGrading.title")}
          align=""
          classes="black text-left"
          color=""
          size="h1"
        />
        <Select
          classes="min-w-1/5"
          options={[
            { value: "hour", label: t("foodGrading.top10") },
            { value: "day", label: t("foodGrading.top15") },
            { value: "week", label: t("foodGrading.top20") },
          ]}
          onChange={() => {}}
        />
      </div>

      <table className="table-fixed mt-5 text-left">
        <thead className="border-b border-slate-400 p-4 pl-8 text-slate-500 ">
          <tr className="uppercase text-gray-600">
            <th>{t("foodGrading.no")}</th>
            <th>{t("foodGrading.itemName")}</th>
            <th>{t("foodGrading.category")}</th>
            <th>{t("foodGrading.order")}</th>
            <th>{t("foodGrading.revenue")}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item) => {
            return (
              <TableRow
                key={item.no}
                no={item.no}
                category={item.category}
                itemName={item.itemName}
                order={item.order}
                revenue={item.revenue}
              />
            );
          })}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default FoodGrading;
