import React from "react";
import { InputProps } from "./Input.type";
import classNames from "classnames";

const Input: React.FC<InputProps> = ({
  value,
  onChange,
  classes = "",
  type = "text",
  placeholder = "",
}) => {
  const InputClasses = classNames(classes);

  return (

    <input
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={InputClasses}
      type={type}
      />
    
  );
};

export default Input;
