import "react-datepicker/dist/react-datepicker.css";
import Select from "../common/select/Select";
import { useEffect, useState } from "react";
import { SalesFiltersProps } from "./SalesFilter.type";
import Weather from "../weather/Weather";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useTranslation } from "react-i18next";
import "./SalesFilter.css"
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import Wrapper from "../common/wrapper/Wrapper";
const SalesFilters: React.FC<SalesFiltersProps> = ({
  sendMenuIdToParent,
  timeSinc,
  dateSinc,
}) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const UserMenus = localStorage.getItem("UserMenus");

  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;

  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });
  const [selectedLocation, setSelectedLocation] = useState(options[0].value);
  const [latitude, setLatitude] = useState<any>(UserMenusParsed[0].Latitude);
  const [longitude, setLongitude] = useState<any>(UserMenusParsed[0].Longitude);
  const [fullAddress, setFullAddress] = useState<any>(UserMenusParsed[0].FullAddress);
  const { t } = useTranslation();


  const handleSelectChange = (selectedOption: any) => {
    setSelectedLocation(selectedOption);
    localStorage.setItem("selectedLocation", selectedOption);
    const selectedLocationData = UserMenusParsed.find(
      (location: any) => location.IdMenu === selectedOption
    );
    if (selectedLocationData) {
      setLatitude(selectedLocation.Latitude);
      setLongitude(parseFloat(selectedLocationData.Longitude));
      setFullAddress(selectedLocationData.FullAddress);
    }
  };
  
  useEffect(() => {
    sendMenuIdToParent(selectedLocation);
  }, [selectedLocation]);
  
useEffect(() => {
  localStorage.setItem("latitude", latitude);
  localStorage.setItem("longitude", longitude);
  localStorage.setItem("fullAddress", fullAddress);
}, [longitude, latitude, fullAddress])  

  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-28 flex flex-col md:flex-row items-center justify-center md:mb-8">
          <div className="w-full">
            <Heading
              align="left"
              children={t("sales.title")}
              classes="pt-16 md:pt-0 min-w-full md:text-4xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("sales.subTitle")}
              classes="font-thin"
              color="text-custom-textGraySec"
              size=""
            />
          </div> 
          <div className="w-full md:hidden">
        <Select
          classes="flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-4"
          options={options}
          onChange={handleSelectChange}
          defaultValue={options[0]}
        />
      </div>
      <div className="flex flex-col h-fit w-full md:w-1/3 mt-4">
        <p className="text-base font-semibold mb-1">{t("sales.dateAndTime")}</p>

      <fieldset>
        {dateSinc === "0" ? (<></>) : (
        <DatePicker
          readOnly
          format="DD/MM/YYYY hh:mm:ss "
          datatype="dd/mm/yyyy"
          defaultValue={dayjs(`'${dateSinc} ${timeSinc}'`, 'DD/MM/YYYY hh:mm:ss A')}
          showHour={true}
          inputReadOnly 
          showNow={false}
          disabled
          suffixIcon={false}
          className="bg-custom-bgBlue py-2 border-none min-w-full custom-datepicker rounded-lg"
        />)}
        
      </fieldset>
      </div>

    </Wrapper>
  );
};

export default SalesFilters;
