import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import { v4 as uuidv4 } from 'uuid';
import { PercentageReportTableProps } from './PercentageReportTable.type';
import { flexRender, getCoreRowModel, getGroupedRowModel, useReactTable } from '@tanstack/react-table';


const PercentageReportTable: React.FC<PercentageReportTableProps> = ({ details, tableHeads, isMainCategoryPage, options }) => {
  const { t } = useTranslation();

  const table = useReactTable({
    ...options,
    getRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    meta: {
      grouping: true,
    },
  });

  const [isMobile, setIsMobile] = useState<boolean>();

useEffect(() => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /iphone|ipad|ipod|android/.test(userAgent);
  const isSmallScreen = window.innerWidth <= 768;

  setIsMobile(isMobileDevice && isSmallScreen);
}, []);
  return (
    <Wrapper onClick={() => {}} classes="w-full">
      
      <div className="rounded-lg shadow-custom4 mt-1">

<table className="w-full text-left rounded-[10px] overflow-hidden">
     <thead className="border-b border-custom-bgLightPurple rounded-[10px] bg-custom-tableHeadBg" style={{borderRadius: "12px !important"}}>
       {table.getHeaderGroups().map(headerGroup => (
         <tr key={headerGroup.id} className=" rounded-[10px] "      
         style={{
           borderTopLeftRadius: '12px',
           borderTopRightRadius: '12px',
           overflow: 'hidden'
         }}>
           {headerGroup.headers.map(header => (
             <th key={header.id} className="text-base font-medium py-2 pl-4 border-b border-custom-bgLightPurple md:border-b-0">
               {header.isPlaceholder
                 ? null
                 : flexRender(
                   header.column.columnDef.header,
                   header.getContext()
                 )}
             </th>
           ))}
         </tr>
       ))}
     </thead>
     <tbody className="">
       {table.getRowModel().rows.map((row, index) => {

const data: any = row.original;
const isMainTotal = data.isMainTotal;
const isGrandTotal = data.isGrandTotal;
const isTotal = data.isTotal;
return(
         <tr 
         className={` ${isTotal ? "bg-custom-tableBgPurple" : isMainTotal ? "bg-custom-tableBgGreen" : isGrandTotal ? "bg-custom-tableBgPurple" : ""}`} 
         key={row.id} 
         style={{ backgroundColor: index % 2 !== 0 ? "#F3F3F3" : "white" }}>
           {row.getVisibleCells().map(cell => (
             <td key={cell.id} className={`${isGrandTotal && cell.column.id === "SubCategory" ? "text-left" : ""} py-4 pl-4`}>
               {flexRender(cell.column.columnDef.cell, cell.getContext())}
             </td>
           ))}
         </tr>
       );
})}
     </tbody>
     {/* <tfoot>
       {table.getFooterGroups().map(footerGroup => (
         <tr key={footerGroup.id}>
           {footerGroup.headers.map(header => (
             <th key={header.id}>
               {header.isPlaceholder
                 ? null
                 : flexRender(
                   header.column.columnDef.footer,
                   header.getContext()
                 )}
             </th>
           ))}
         </tr>
       ))}
     </tfoot> */}
   </table> 
</div>
<div className={`text-[8px] md:text-xs flex justify-between items-center w-[97%] mx-auto mt-2`}>
  <span className="flex items-center gap-2">
    {t("periodReport.showing")}
    <select
      className="border rounded-lg px-2 py-2"
      value={table.getState().pagination.pageSize}
      onChange={e => {
        table.setPageSize(Number(e.target.value));
      }}
    >
      {[10, 20, 30, 40, 50].map(pageSize => (
        <option key={pageSize} value={pageSize}>
          {pageSize}
        </option>
      ))}
    </select>
    <p style={{ color: "#5A5A5A" }}>
      {t("periodReport.itemsPerPage")}
    </p>
  </span>

  <span>
    {(() => {
      const totalPages = table.getPageCount();
      const currentPage = table.getState().pagination.pageIndex;
      const maxButtons = isMobile ? 2 : 5;
      const halfMaxButtons = Math.floor(maxButtons / 2);

      let startPage = Math.max(0, currentPage - halfMaxButtons);
      let endPage = Math.min(totalPages - 1, currentPage + halfMaxButtons);

      if (endPage - startPage + 1 < maxButtons) {
        if (startPage === 0) {
          endPage = Math.min(maxButtons - 1, totalPages - 1);
        } else if (endPage >= totalPages - 2) {
          startPage = Math.max(0, totalPages - maxButtons);
        }
      }

      return (
        <>
          {startPage > 0 && (
            <button
              className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
              onClick={() => table.setPageIndex(0)}
            >
              1
            </button>
          )}
          {startPage > 1 && <span className="px-2 py-1 md:px-3 md:py-1.5">...</span>}
          {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
            <button
              key={startPage + i}
              className={`px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1 ${currentPage === startPage + i ? 'border border-custom-bgPurple text-custom-bgPurple rounded-lg' : ''}`}
              onClick={() => table.setPageIndex(startPage + i)}
            >
              {startPage + i + 1}
            </button>
          ))}
          {endPage < totalPages - 3 && <span className="px-2 py-1 md:px-3 md:py-1.5">...</span>}
          {endPage < totalPages - 2 && (
            <>
              <button
                className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
                onClick={() => table.setPageIndex(totalPages - 2)}
              >
                {totalPages - 1}
              </button>
              <button
                className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
                onClick={() => table.setPageIndex(totalPages - 1)}
              >
                {totalPages}
              </button>
            </>
          )}
        </>
      );
    })()}
    <button
      className="p-2 hidden md:inline"
      onClick={() => table.nextPage()}
      disabled={!table.getCanNextPage()}
      style={{ color: "#5A5A5A" }}
    >
      {t("periodReport.next")}
    </button>
    <button
      className="p-2 hidden md:inline"
      onClick={() => table.lastPage()}
      disabled={!table.getCanPreviousPage()}
      style={{ color: "#5A5A5A" }}
    >
      {t("periodReport.end")}
    </button>
  </span>
</div>
    </Wrapper>
  );
};

export default PercentageReportTable;
