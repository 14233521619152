const GreenArrowUpIcon = () => {
  const svgIcon = `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99984 15.8334V4.16669M9.99984 4.16669L4.1665 10M9.99984 4.16669L15.8332 10" stroke="#12B76A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
      `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default GreenArrowUpIcon;
