import React from "react";
// import { useTranslation } from 'react-i18next'
import { TableRowProps } from "./FoodGrading.type";

const TableRow: React.FC<TableRowProps> = ({
  no,
  itemName,
  category,
  order,
  revenue,
}) => {
  //   const { t } = useTranslation()

  return (
    <tr className="border-b border-slate-100  p-4 pl-8 text-black">
      <td className="py-4">{no}</td>
      <td>{itemName}</td>
      <td>{category}</td>
      <td>{order}</td>
      <td>${revenue}k</td>
    </tr>
  );
};

export default TableRow;
