// useDateFormatter.js (or .ts for TypeScript)
import { useCallback } from 'react';

const useDateFormatter = () => {
  // First date formatting function
  const formatDateAndTime = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
};



  // Second date formatting function
  const formatDate = (date: Date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDateWithoutTime = (dateString: string) => {
    const date = new Date(dateString);
    // Set hours, minutes, and seconds to zero
    date.setHours(0, 0, 0, 0);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    
    // Check if the time part is midnight (00:00:00)
    const isMidnight = date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
    
    if (isMidnight) {
        // If time is midnight, only display the date
        return `${day}/${month}/${year}`;
    } else {
        // Otherwise, display both date and time
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
};

  return { formatDateAndTime, formatDate, formatDateWithoutTime };
};

export default useDateFormatter;
