import { title } from "process";
import React, { useEffect, useState } from "react";
import ToggleOn from "../../icons/ToggleOn";
import { Props } from "./ToggleElement.type";
import ToggleOff from "../../icons/ToggleOff";

const ToggleElement: React.FC<Props> = ({
    title, 
    id,
    defaultValue
}) => {
    const localStorageKey = `toggle_${id}`;

    const getInitialTheme = () => {
        const savedTheme = localStorage.getItem(localStorageKey);
        if (savedTheme !== null) {
            return savedTheme === 'true';
        }

        // If there's no saved theme, use OS preference
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
    };

    
    
    const initialToggleState = localStorage.getItem(localStorageKey);
    const [toggleState, setToggleState] = useState<boolean>(getInitialTheme);

    const handleToggle = () => {
        setToggleState(prevState => {
            const newState = !prevState;

            // If this is the dark mode toggle
            if (id === "2") {
                if (newState) {
                    document.documentElement.classList.add("dark");
                } else {
                    document.documentElement.classList.remove("dark");
                }
            }

            return newState;
        });
    };


    useEffect(() => {
        localStorage.setItem(localStorageKey, toggleState.toString());

        // Apply the theme based on the toggle state
        if (id === "2") {
            if (toggleState) {
                document.documentElement.classList.add("dark");
            } else {
                document.documentElement.classList.remove("dark");
            }
        }
    }, [toggleState, localStorageKey, id]);

  return (
  <div className="flex justify-between items-center px-3 py-2">
    {title}
    <span onClick={handleToggle}>
        {toggleState ?   <ToggleOn/> : <ToggleOff />}
    </span>
  </div>
  );
};

export default ToggleElement;
