import { useEffect, useRef, useState } from "react";
import Select from "../common/select/Select";
import Wrapper from "../common/wrapper/Wrapper";
import { useTranslation } from "react-i18next";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { PeriodReportFilterProps } from "./PeriodReportFilters.type";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";

const PeriodReportFilter: React.FC<PeriodReportFilterProps> = ({
  sendMenuIdToParent,
  sendFromDateToParent,
  sendToDateToParent
}) => {
  const { t } = useTranslation();
  const UserMenus = localStorage.getItem("UserMenus");
  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });
  const [selectedLocation, setSelectedLocation] = useState(options[0].value);
  const [fromTime, setFromTime] = useState("10:00");
  const [toTime, setToTime] = useState("10:00");
  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>();  
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const [backendAllowedDates, setBackendAllowedDates] = useState<any>();
  const location = useLocation();
  const [allowedDates, setAllowedDates] = useState<Date[]>([]);
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<any>();
  const [allowedDatesToPicker, setAllowedDatesToPicker] = useState<any>();
  const [currentFromMonth, setCurrentFromMonth] = useState<any>(new Date());
  const [currentToMonth, setCurrentToMonth] = useState<any>(new Date());
  const [allowedFrom, setAllowFrom] = useState<any>();
  const [allowedTo, setAllowTo] = useState<any>();
  const ref = useRef<any>();
  
  const handleSelectChange = (selectedOption: any) => {
    setSelectedLocation(selectedOption);
  };
  
  useEffect(() => {
    if (
       location.pathname === "/reports/periodReport"
    ) {
      if (selectedLocation) {
        
        const getData = async () => {
          const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
          const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
          const requestBody = {
            IdMenu: selectedLocation,
            "TargetDate": formatDate(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
            "TargetDateTo": formatDate(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
          };


          try {
            const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            setBackendAllowedDates(apiResponse.data);
            setAllowedDatesFromPicker(apiResponse.data);
            setAllowedDatesToPicker(apiResponse.data);
          } catch (error) {

          } finally {
        
          }
        };

        getData();
      }
    }
  }, [selectedLocation, location.pathname]);

  useEffect(() => {
    if (
      location.pathname === "/reports/periodReport"
   ) {
     if (selectedLocation) {
       
       const getData = async () => {
         const startOfMonth = new Date(currentFromMonth.getFullYear(), currentFromMonth.getMonth(), 1);
         const endOfMonth = new Date(currentFromMonth.getFullYear(), currentFromMonth.getMonth() + 1, 0);
         const requestBody = {
           IdMenu: selectedLocation,
           "TargetDate": formatDate(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
           "TargetDateTo": formatDate(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
         };


         try {
           const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
             method: "POST",
             headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${token}`,
             },
             data: requestBody,
           });
           setAllowedDatesFromPicker(apiResponse.data)
         } catch (error) {

         } finally {
       
         }
       };

       getData();
     }
   }
  },[currentFromMonth])

  useEffect(() => {
    if (
      location.pathname === "/reports/periodReport"
   ) {
     if (selectedLocation) {
       
       const getData = async () => {
         const startOfMonth = new Date(currentToMonth.getFullYear(), currentToMonth.getMonth(), 1);
         const endOfMonth = new Date(currentToMonth.getFullYear(), currentToMonth.getMonth() + 1, 0);
         const requestBody = {
           IdMenu: selectedLocation,
           "TargetDate": formatDate(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
           "TargetDateTo": formatDate(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
         };


         try {
           const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
             method: "POST",
             headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${token}`,
             },
             data: requestBody,
           });
           setAllowedDatesToPicker(apiResponse.data)
         } catch (error) {

         } finally {
       
         }
       };

       getData();
     }
   }
  },[currentToMonth])


  useEffect(() => {
    if (backendAllowedDates) {
      const targetDates = backendAllowedDates.map((date: any) => new Date(date.TargetDate));
      setAllowedDates(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
    }
  }, [backendAllowedDates]);

  useEffect(() => {
    if (allowedDates.length > 0) {
      setFromDate(formatDate(allowedDates[allowedDates.length - 1]));
      setToDate(formatDate(allowedDates[0]));
    }
  }, [allowedDates]);

  useEffect(() => {
    sendMenuIdToParent(selectedLocation);
    sendFromDateToParent(fromDate);
    sendToDateToParent(toDate)
  }, [selectedLocation, fromDate, toDate]);

  const handleFocus = (e: any) => {
    const { target } = e;

    if (target) {
      target.readOnly = true;  // -------> this for all others
      target.blur(); //  ------> this for ios iphone
    }
  };

  const handleMonthChange = (date: Date) => {
    setCurrentMonth(date);
  };
  const handleFromMonthChange = (date: Date) => {
    setCurrentFromMonth(date)
  }
  const handleToMonthChange = (date: Date) => {
    setCurrentToMonth(date)
  }
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const getFirstAndLastDayOfMonth = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return { firstDay, lastDay };
    };
  
    const { firstDay, lastDay } = getFirstAndLastDayOfMonth();
  
    const isDateInCurrentMonth = (date: any) => {
      const d = new Date(date);
      return d >= firstDay && d <= lastDay;
    };
    const currentMonthAllowedDates = allowedDates.filter(isDateInCurrentMonth);
  
    if (currentMonthAllowedDates.length > 0) {
      setFromDate(formatDate(currentMonthAllowedDates[currentMonthAllowedDates.length - 1]));
      setToDate(formatDate(currentMonthAllowedDates[0]));
    }
  }, [allowedDates]);

  useEffect(() => {
    if (backendAllowedDates) {
      const targetDates = backendAllowedDates.map((date: any) => new Date(date.TargetDate));
      setAllowedDates(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
    }
  }, [backendAllowedDates]);

  useEffect(() => {
    if(allowedDatesFromPicker) {
      const targetDates = allowedDatesFromPicker.map((date: any) => new Date(date.TargetDate));
      setAllowFrom(targetDates.sort((a:any, b:any) => b.getTime() - a.getTime()))
    }
  }, [allowedDatesFromPicker])

  useEffect(() => {
    if(allowedDatesToPicker) {
      const targetDates = allowedDatesToPicker.map((date: any) => new Date(date.TargetDate));
      setAllowTo(targetDates.sort((a:any, b:any) => b.getTime() - a.getTime()))
    }
  }, [allowedDatesToPicker])

  const disabledDate = (current: any) => {
    const currentDate = current.toDate(); // Convert dayjs object to Date
    return !allowedFrom.some((allowedFrom: any) => 
      allowedFrom.toDateString() === currentDate.toDateString() // Compare as strings
    );
  };
  const disabledToDate = (current: any) => {
    const currentDate = current.toDate(); // Convert dayjs object to Date
    return !allowedTo.some((allowedTo: any) => 
      allowedTo.toDateString() === currentDate.toDateString() // Compare as strings
    );
  };



  // console.log(allowedDates)
  // console.log(allowedFrom)
  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-24 mt-1 flex flex-col md:flex-row items-center justify-center">
      <div className="w-full flex flex-col">

        <div className="w-full">
            <Heading
              align="left"
              children={t("periodReport.title")}
              classes="pt-16 md:pt-0 min-w-full md:text-4xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("periodReport.subTitle")}
              classes="font-thin"
              color="text-custom-textGraySec"
              size=""
            />
          </div>

        <Select
          classes="md:hidden md:w-1/3 flex justify-center rounded-lg border border-custom-bgBlue bg-white relative mt-4 mb-2"
          options={options}
          onChange={handleSelectChange}
          defaultValue={options[0]}
          />
          </div>
        <div className="w-full md:w-1/2 mt-4 flex gap-4 ">

        
        <div className="text-left w-1/2">
          <p className="mb-2">{t("periodReport.from")}</p>
          <div className="flex flex-col h-14 justify-between">
            <fieldset>
              {fromDate && 
        <DatePicker
          ref={ref}
          format="DD/MM/YYYY hh A"
          datatype="dd/mm/yyyy"
          defaultValue={dayjs(`'${fromDate} 12 AM'`, 'DD/MM/YYYY hh A')}
          onChange={(date, dateString) => {
            // Ensure dateString is a string
            if (typeof dateString === 'string') {
              const [datePart, timePart] = dateString.split(' '); // Split the date and time
              if(timePart){

                const hour = parseInt(timePart.split(' ')[0], 10); // Get the hour from the time part
                const isPM = timePart.includes('PM');
                let hourIn24 = hour;
                
                if (isPM && hourIn24 < 12) {
                  hourIn24 += 12; // Convert PM to 24-hour format
                } else if (!isPM && hourIn24 === 12) {
                  hourIn24 = 0; // Convert 12 AM to 0
                }
                
                const formattedTime = hourIn24.toString().padStart(2, '0');
                setFromTime(formattedTime)
              }
              setFromDate(datePart)

            } else {
              console.error('dateString is not a string:', dateString);
            }
          }}
          showTime={{ use12Hours: false }}
          showHour={true}
          inputReadOnly 
          disabledDate={disabledDate}
          showNow={false}
          onPanelChange={(date: any, mode) => {
            if (mode === 'date') {
              handleFromMonthChange(date.toDate());
            }
          }}
          placeholder={t("datePicker.placeholder")}
          className="bg-custom-bgBlue text-white py-2 border-none min-w-full"
        />
        }
            </fieldset>
            
            
          </div>
        </div>
        <div className="text-left w-1/2">
          <p className="mb-2">{t("periodReport.to")}</p>
          <div className="flex flex-col h-14 justify-between">
      
          <fieldset>
            {toDate && 
        <DatePicker
          ref={ref}
          format="DD/MM/YYYY hh A"
          datatype="dd/mm/yyyy"
          removeIcon={false}
          defaultValue={dayjs(`'${toDate} 11 AM'`, 'DD/MM/YYYY hh A')}
          onChange={(date, dateString) => {
            // Ensure dateString is a string
            if (typeof dateString === 'string') {
              const [datePart, timePart] = dateString.split(' '); // Split the date and time
              if(timePart){

                const hour = parseInt(timePart.split(' ')[0], 10); // Get the hour from the time part
                const isPM = timePart.includes('PM');
                let hourIn24 = hour;
                
                if (isPM && hourIn24 < 12) {
                  hourIn24 += 12; // Convert PM to 24-hour format
                } else if (!isPM && hourIn24 === 12) {
                  hourIn24 = 0; // Convert 12 AM to 0
                }
                
                const formattedTime = hourIn24.toString().padStart(2, '0');
                setToTime(formattedTime)
              }
              setToDate(datePart)
            } else {
              console.error('dateString is not a string:', dateString);
            }
          }}
          showTime={{ use12Hours: false }}
          showHour={true}
          inputReadOnly 
          disabledDate={disabledToDate}
          showNow={false}
          onPanelChange={(date: any, mode) => {
            if (mode === 'date') {
              handleToMonthChange(date.toDate());
            }
          }}
          placeholder={t("datePicker.placeholder")}
          className="bg-custom-bgBlue text-white py-2 border-none min-w-full"
        />
}
          </fieldset>
          </div>
        </div>
        </div>
    </Wrapper>
  );
};

export default PeriodReportFilter;
