import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { SelectProps } from "./Select.type";
import ArrowDownIcon from "../../icons/ArrowDownIcon";
import ArrowUpIcon from "../../icons/ArrowUpIcon";

const Select: React.FC<SelectProps> = ({
  options,
  onChange,
  classes,
  defaultValue,
  title,
  color,
  textColor,
  iconColor
}) => {
  const [selectedValue, setSelectedValue] = useState(
    defaultValue ? defaultValue.value : null
  );
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const bgColor = color || "bg-white";
  const txtColor = textColor || "text-custom-textBlackColor";
  const iconCol = iconColor || "";


  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      if (isOpen && selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", closeDropdown);

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isOpen]);

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    onChange(value);
    setIsOpen(false);
  };

  const selectClasses = classNames(
    `${bgColor} ${txtColor} w-full text-start py-2.5 border focus:outline-none focus:ring-0 focus:border-gray-200`,
    classes
  );

  return (
    <div className="relative text-start w-full" ref={selectRef}>
      <span className="absolute top-5 font-base ">
      {title}
      </span>
      <div
        className={selectClasses}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="w-full pl-3.5 ">{options && options.find(option => option.value === selectedValue)?.label || (defaultValue ? defaultValue.label : 'Select an option')}</span>
        <span className="absolute top-1/2 right-2 transform -translate-y-1/2 pointer-events-none ">
          {isOpen ? <ArrowUpIcon color={`${iconCol}`}/> : <ArrowDownIcon color={`${iconCol}`}/>}
        </span>
      </div>
      {isOpen && (
       <div className={`bg-white font-semibold absolute top-full left-0 w-full border-gray-300 rounded-md shadow-lg z-50`}>
          {options.map(({ value, label }) => (
            <div
              key={value}
              className={`${txtColor} py-2 px-4 hover:bg-gray-100 cursor-pointer border-gray-300 border rounded-lg ${
                selectedValue === value ? 'bg-gray-100 ' : ''
              }`}
              onClick={() => handleSelect(value)}
            >
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
