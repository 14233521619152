import { useTranslation } from "react-i18next";
import AnalyticCard from "../analyticCard/AnalyticCard";
import Wrapper from "../common/wrapper/Wrapper";
import DrinkIcon from "../icons/DrinkIcon";
import FoodIcon from "../icons/FoodIcon";
import GuestIcon from "../icons/GuestIcon";
import RevenueIcon from "../icons/RevenueIcon";
import TimeIcon from "../icons/TimeIcon";
import { Props } from "./AnalyticComponent.type";

const AnalyticComponent: React.FC<Props> = ({
  totalRevenue,
  turnoverPerGuest,
  tableLeadTime,
  betweenDrinks,
  betweenFood,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper onClick={() => {}} classes="w-11/12 mx-auto mt-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-5 gap-4">
        <AnalyticCard
          value={`$${totalRevenue}`}
          icon={<RevenueIcon />}
          percentage="+2.5%"
          title={t("analyticComponent.totalRevenue")}
        />
        <AnalyticCard
          value={`$${turnoverPerGuest}`}
          icon={<GuestIcon />}
          percentage="+2.5%"
          title={t("analyticComponent.turnoverPerGuest")}
        />
        <AnalyticCard
          value="86 min"
          icon={<TimeIcon />}
          percentage="-2.5%"
          title={t("analyticComponent.tableLeadTime")}
        />
        <AnalyticCard
          value="11 min"
          icon={<DrinkIcon />}
          percentage="+1.5%"
          title={t("analyticComponent.betweenDrinks")}
        />
        <AnalyticCard
          value="14 min"
          icon={<FoodIcon />}
          percentage="-1.5%"
          title={t("analyticComponent.betweenFood")}
        />
      </div>
    </Wrapper>
  );
};

export default AnalyticComponent;
