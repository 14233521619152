const RevenueIcon = () => {
  const svgIcon = `
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="4" y="4" width="48" height="48" rx="24" fill="#F4EBFF"/>
  <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8"/>
  <path d="M29.5295 24.3519C28.9571 24.7599 28.2566 25 27.5 25C25.567 25 24 23.433 24 21.5C24 19.567 25.567 18 27.5 18C28.753 18 29.8522 18.6584 30.4705 19.6481M22 36.0872H24.6103C24.9506 36.0872 25.2889 36.1277 25.6188 36.2086L28.3769 36.8789C28.9753 37.0247 29.5988 37.0388 30.2035 36.9214L33.253 36.3281C34.0585 36.1712 34.7996 35.7854 35.3803 35.2205L37.5379 33.1217C38.154 32.5234 38.154 31.5524 37.5379 30.9531C36.9832 30.4134 36.1047 30.3527 35.4771 30.8103L32.9626 32.6449C32.6025 32.9081 32.1643 33.0498 31.7137 33.0498H29.2855L30.8311 33.0498C31.7022 33.0498 32.4079 32.3633 32.4079 31.5159V31.2091C32.4079 30.5055 31.9156 29.892 31.2141 29.7219L28.8286 29.1417C28.4404 29.0476 28.0428 29 27.6431 29C26.6783 29 24.9319 29.7988 24.9319 29.7988L22 31.0249M36 22.5C36 24.433 34.433 26 32.5 26C30.567 26 29 24.433 29 22.5C29 20.567 30.567 19 32.5 19C34.433 19 36 20.567 36 22.5ZM18 30.6L18 36.4C18 36.9601 18 37.2401 18.109 37.454C18.2049 37.6422 18.3578 37.7951 18.546 37.891C18.7599 38 19.0399 38 19.6 38H20.4C20.9601 38 21.2401 38 21.454 37.891C21.6422 37.7951 21.7951 37.6422 21.891 37.454C22 37.2401 22 36.9601 22 36.4V30.6C22 30.0399 22 29.7599 21.891 29.546C21.7951 29.3578 21.6422 29.2049 21.454 29.109C21.2401 29 20.9601 29 20.4 29L19.6 29C19.0399 29 18.7599 29 18.546 29.109C18.3578 29.2049 18.2049 29.3578 18.109 29.546C18 29.7599 18 30.0399 18 30.6Z" stroke="#7F56D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
    
      `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default RevenueIcon;
