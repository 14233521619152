import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./index.css";
import NavbarComponent from "./components/navbarComponent/NavbarComponent";
import "./i18n";
import Wrapper from "./components/common/wrapper/Wrapper";
import { PrivilegesProvider } from "./context/privilegesContext";
import { AppRoutes } from "./types/Enums";
import LogIn from "./pages/logIn/LogIn";
import AccountSettings from "./pages/accountSettings/AccountSettings";
import Reports from "./pages/reports/Reports";
import { LoadingLiveDataContext } from "./context/isLoadingLiveDataContext";
import Loader from "./components/common/loader/Loader";
import { useContext, useEffect, useState } from "react";
import Settings from "./pages/settings/Settings";
import TopNavbar from "./components/common/topNavbar/TopNavbar";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "./context/themeContext";

const queryClient = new QueryClient();

const LoginElement = () => {
  const isLogIn = localStorage.getItem("isLogIn");

  return isLogIn === null ? <LogIn /> : <Navigate to="/" />;
};
const HomeElement = () => {
  const isLogIn = localStorage.getItem("isLogIn");

  return isLogIn === "true" ? (
    <div className="w-11/12 mx-auto pt-14">
      You are on home page</div>
  ) : (
    <Navigate to="/login" />
  );
};
const ReportsElement = () => {
  const isLogIn = localStorage.getItem("isLogIn");

  return isLogIn === "true" ? <Reports /> : <Navigate to="/login" />;
};
const AccountSettingsElement = () => {
  const isLogIn = localStorage.getItem("isLogIn");

  return isLogIn === "true" ? <AccountSettings /> : <Navigate to="/login" />;
};
const CalendarElement = () => {
  const isLogIn = localStorage.getItem("isLogIn");

  return isLogIn === "true" ? (
    <div className="text-white">Calendar</div>
  ) : (
    <Navigate to="/login" />
  );
};
const RegisterElement = () => {
  const isLogIn = localStorage.getItem("isLogIn");

  return isLogIn === "true" ? (
    <div className="text-white">Register</div>
  ) : (
    <Navigate to="/login" />
  );
};
const ProductsElement = () => {
  const isLogIn = localStorage.getItem("isLogIn");

  return isLogIn === "true" ? (
    <div className="text-white">Products</div>
  ) : (
    <Navigate to="/login" />
  );
};
const MarketingElement = () => {
  const isLogIn = localStorage.getItem("isLogIn");

  return isLogIn === "true" ? (
    <div className="text-white">Marketing</div>
  ) : (
    <Navigate to="/login" />
  );
};

const App = () => {
  // const [cookies] = useCookies(["isLogIn"]);
  const location = useLocation();
  const { isLoadingLiveData } = useContext(LoadingLiveDataContext);
  const [isMobile, setIsMobile] = useState<boolean>();
  // const isLogedIn = cookies.isLogIn;
  const [isLogIn, setIsLogIn] = useState<any>(null);
  useEffect(() => {
    const storedValue = localStorage.getItem("isLogIn");
    setIsLogIn(storedValue === "true"); // Convert string to boolean
  }, [location]);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /iphone|ipad|ipod|android/.test(userAgent);
    const isSmallScreen = window.innerWidth <= 768;
  
    setIsMobile(isMobileDevice && isSmallScreen);
  }, []);

  const [selectedFilter, setSelectedFilter] = useState(location.pathname);

  const { t, i18n } = useTranslation();

  const filterOptions = [
    // {
    //   value: "dashboard",
    //   label: t("reportsLinks.dashboard"),
    //   path: "/reports/dashboard",
    // },
    { value: "sales", label: t("reportsLinks.sales"), path: "/reports/sales" },
    {
      value: "z-report",
      label: t("reportsLinks.zreport"),
      path: "/reports/zreport",
    },
    {
      value: "period-report",
      label: t("reportsLinks.periodReport"),
      path: "/reports/periodReport"
    },
    {
      value: "sold-products",
      label: t("reportsLinks.soldProducts"),
      path: "/reports/soldProducts"
    },
    {
      value: "service-report",
      label: t("reportsLinks.percentageReport"),
      path: "/reports/percentageReport"
    },
    {
      value: "main-category",
      label: t("reportsLinks.mainCategory"),
      path: "/reports/mainCategory"
    }
  ];

  const handleFilterChange = (value: string) => {
    setSelectedFilter(value);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
      <PrivilegesProvider>
        {isLoadingLiveData && <Loader />}
        <Wrapper
          classes="flex justify-between overflow-hidden"
          onClick={() => {}}
        >
          <div className="md:w-1/5" >
            {isLogIn === true ? <NavbarComponent /> : <></>}
          </div>
          <div
            className={` ${isLogIn && isMobile ? "w-full" : isLogIn ? "w-4/5" : ""}`}
          >
            {isLogIn &&      <TopNavbar
        filterOptions={filterOptions}
        onFilterChange={handleFilterChange}
        activeFilter={selectedFilter}
        />}
   
            <Routes>
              <Route path={AppRoutes.Home} element={<HomeElement />} />
              <Route path={AppRoutes.Login} element={<LoginElement />} />
              {/* <Route path={AppRoutes.ForgotPw} element={<ForgotPw />} /> */}
              <Route
                path={AppRoutes.AccountSettings}
                element={<AccountSettingsElement />}
              />
              <Route path={AppRoutes.Reports} element={<ReportsElement />}>
                <Route
                  path={AppRoutes.ReportsDashboard}
                  element={<ReportsElement />}
                />
                <Route
                  path={AppRoutes.ReportsSales}
                  element={<ReportsElement />}
                />
                <Route
                  path={AppRoutes.ReportsZreport}
                  element={<ReportsElement />}
                />
                <Route
                  path={AppRoutes.ReportsPeriodReport} 
                  element={<ReportsElement />}
                />
                <Route 
                path={AppRoutes.SoldProducts}
                element={<ReportsElement />}
                />
                <Route 
                path={AppRoutes.PercentageReport}
                element={<ReportsElement />}
                />
                <Route 
                path={AppRoutes.MainCategory}
                element={<ReportsElement />}
                />
                <Route
                  path={AppRoutes.ReportsPerformance}
                  element={<ReportsElement />}
                />
                <Route
                  path={AppRoutes.ReportsMarketing}
                  element={<MarketingElement />}
                />
                <Route
                  path={AppRoutes.ReportsProducts}
                  element={<ReportsElement />}
                />
                <Route
                  path={AppRoutes.ReportsEmployees}
                  element={<ReportsElement />}
                />
                <Route
                  path={AppRoutes.ReportsAdministration}
                  element={<ReportsElement />}
                />
              </Route>
              <Route path={AppRoutes.Calendar} element={<CalendarElement />} />
              <Route path={AppRoutes.Register} element={<RegisterElement />} />
              <Route path={AppRoutes.Products} element={<ProductsElement />} />
              <Route
                path={AppRoutes.Marketing}
                element={<MarketingElement />}
              />
              <Route path={AppRoutes.Settings} element={<Settings />} />
              <Route path="*" element={<div>Not found</div>} />
            </Routes>
          </div>
        </Wrapper>
      </PrivilegesProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
