const RedArrowDownIcon = () => {
  const svgIcon = `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99984 4.16669V15.8334M9.99984 15.8334L15.8332 10M9.99984 15.8334L4.1665 10" stroke="#F04438" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>        
      `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default RedArrowDownIcon;
