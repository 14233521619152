import { useTranslation } from "react-i18next";
import Button from "../common/button/Button";
import { useEffect, useState } from "react";
import Select from "../common/select/Select";
import ExportIcon from "../icons/ExportIcon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../icons/CalendarIcon";
import { useCookies } from "react-cookie";
import { ButtonName, ReportsFiltersProps } from "./ReportsFilters.type";

const ReportsFilters: React.FC<ReportsFiltersProps> = ({
  sendMenuIdToParent,
}) => {
  const { t } = useTranslation();
  const [, setCookie] = useCookies();
  const [activeButton, setActiveButton] = useState<ButtonName | null>("month");

  const handleButtonClick = (buttonName: ButtonName) => {
    setActiveButton(buttonName);
  };

  const getButtonClasses = (buttonName: ButtonName) => {
    let classes = "py-2 px-4 font-semibold hover:text-white flex flex-row ";

    if (activeButton === buttonName) {
      classes += "bg-custom-bgPurple text-white";
    } else {
      classes += "bg-white text-gray-700";
    }

    return classes;
  };

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  // const today = new Date();

  const [startDate, setStartDate] = useState<Date>(yesterday);
  const [endDate] = useState<Date>(new Date());

  const UserMenus = localStorage.getItem("UserMenus");

  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;

  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });

  const [selectedLocation, setSelectedLocation] = useState(options[0].value);

  const handleSelectChange = (selectedOption: any) => {
    setSelectedLocation(selectedOption);
  };

  useEffect(() => {
    sendMenuIdToParent(selectedLocation);
  }, [selectedLocation]);
  return (
    <div className="flex justify-between w-11/12 mx-auto pt-20 flex-col">
      <div className="flex mt-10 gap-2">
        <div className="flex justify-between rounded-xl border-2 border-gray-200">
           <DatePicker 
        selected={startDate}
        onChange={(date: Date) => setStartDate(date || yesterday)}
        selectsStart
        startDate={startDate}
        minDate={yesterday}
        wrapperClassName="flex items-center justify-between "
            className="text-center px-4 text-base text-black bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
            showIcon={true}
            icon={<span className="flex items-center"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 7.50007H2.25M12 1.50007V4.50007M6 1.50007V4.50007M5.85 16.5001H12.15C13.4101 16.5001 14.0402 16.5001 14.5215 16.2548C14.9448 16.0391 15.289 15.6949 15.5048 15.2716C15.75 14.7903 15.75 14.1602 15.75 12.9001V6.60007C15.75 5.33995 15.75 4.7099 15.5048 4.22859C15.289 3.80523 14.9448 3.46102 14.5215 3.24531C14.0402 3.00007 13.4101 3.00007 12.15 3.00007H5.85C4.58988 3.00007 3.95982 3.00007 3.47852 3.24531C3.05516 3.46102 2.71095 3.80523 2.49524 4.22859C2.25 4.7099 2.25 5.33995 2.25 6.60007V12.9001C2.25 14.1602 2.25 14.7903 2.49524 15.2716C2.71095 15.6949 3.05516 16.0391 3.47852 16.2548C3.95982 16.5001 4.58988 16.5001 5.85 16.5001Z" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg></span>}
          />
        </div>
        <div className="flex justify-between rounded-xl border-2 border-gray-200">
        <DatePicker 
        selected={endDate}
        onChange={(date: Date) => setStartDate(date || yesterday)}
        selectsStart
        startDate={startDate}
        minDate={yesterday}
        wrapperClassName="flex items-center justify-between "
            className="text-center px-4 text-base text-black bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
            showIcon={true}
            icon={<span className="flex items-center"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 7.50007H2.25M12 1.50007V4.50007M6 1.50007V4.50007M5.85 16.5001H12.15C13.4101 16.5001 14.0402 16.5001 14.5215 16.2548C14.9448 16.0391 15.289 15.6949 15.5048 15.2716C15.75 14.7903 15.75 14.1602 15.75 12.9001V6.60007C15.75 5.33995 15.75 4.7099 15.5048 4.22859C15.289 3.80523 14.9448 3.46102 14.5215 3.24531C14.0402 3.00007 13.4101 3.00007 12.15 3.00007H5.85C4.58988 3.00007 3.95982 3.00007 3.47852 3.24531C3.05516 3.46102 2.71095 3.80523 2.49524 4.22859C2.25 4.7099 2.25 5.33995 2.25 6.60007V12.9001C2.25 14.1602 2.25 14.7903 2.49524 15.2716C2.71095 15.6949 3.05516 16.0391 3.47852 16.2548C3.95982 16.5001 4.58988 16.5001 5.85 16.5001Z" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg></span>}
          />
        </div>

        <div className="flex justify-between rounded-xl border-2 border-gray-200">
          <Select
            classes="pr-10  "
            options={options}
            onChange={handleSelectChange}
            defaultValue={options[0]}
          />
        </div>
      </div>
      <div className="flex gap-2 mt-10">
        <div className="flex">
          <Button
            label={t("reportsFilters.day")}
            onClick={() => handleButtonClick("day")}
            classes={`${getButtonClasses("day")} rounded-l-lg`}
          />
          <Button
            label={t("reportsFilters.week")}
            onClick={() => handleButtonClick("week")}
            classes={getButtonClasses("week")}
          />
          <Button
            label={t("reportsFilters.month")}
            onClick={() => handleButtonClick("month")}
            classes={`${getButtonClasses("month")} rounded-r-lg`}
          />
        </div>
        <Button
          label={
            <>
              {t("reportsFilters.export")} <ExportIcon />
            </>
          }
          onClick={() => handleButtonClick("custom")}
          type="secondary"
          classes="flex rounded-lg"
        />
      </div>
    </div>
  );
};

export default ReportsFilters;
