import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from "react";
import { SoldProductsFiltersProps } from "./SoldProductsReportFilters.type";
import Select from "../common/select/Select";
import { useTranslation } from "react-i18next";
import Dropdown from "../common/dropdown/Dropdown";
import { API_ROUTES } from "../../config";
import i18n from "../../i18n";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import Wrapper from "../common/wrapper/Wrapper";


const SoldProductsReportFilters: React.FC<SoldProductsFiltersProps> = ({
  sendFiltersToParent
}) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const UserMenus = localStorage.getItem("UserMenus");

  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;

  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });
  const {t}   = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState(options[0].value);
  const [selectedMainCategory, setSelectedMainCategory] = useState<number[]>([0]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<number[]>([0]);
  const [subCategoryOptions, setSubCategoryOptions] = useState<any>([0]);
  const [productsOptions, setProductsOptions] = useState<any>([0])
  const menuId = selectedLocation;
  const token = localStorage.getItem("token");
  const { fetchData } = useAxios();
  const location = useLocation();
  const [timePeriod, setTimePeriod] = useState<any>([]);
  const [mainCategory, setMainCategory] = useState<any>([]);
  const [subCategory, setSubCategory] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [taxParentType, setTaxParentType] = useState<any>([]);
  const [taxType, setTaxType] = useState<any>([]);
  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>();
  const [fromTime, setFromTime] = useState("00");
  const [toTime, setToTime] = useState("23");
  const [selectedTaxParent, setSelectedTaxParent] = useState<any>();
  const [selectedTax, setSelectedTax] = useState<any>("0");
  const [exportOptions, setExportOptions] = useState<any>([]);
  const [selectedExportType, setSelectedExportType] = useState<any>("0")
  const [selectedProducts, setSelectedProducts] = useState<any>("0")
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<any>("2");
  const [backendAllowedDates, setBackendAllowedDates] = useState<any>();
  const [allowedDates, setAllowedDates] = useState<Date[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<any>();
  const [allowedDatesToPicker, setAllowedDatesToPicker] = useState<any>();
  const [currentFromMonth, setCurrentFromMonth] = useState<any>(new Date());
  const [currentToMonth, setCurrentToMonth] = useState<any>(new Date());
  const [allowedFrom, setAllowFrom] = useState<any>();
  const [allowedTo, setAllowTo] = useState<any>();
  const isTaxParentGroup = JSON.parse(localStorage.getItem("toggle_1") || "false");
  const ref = useRef<any>();
  const initialRender = useRef(true);
  
  useEffect(() => {
    if (menuId) {
      const getData = async () => {
        
        // setIsLoadingLiveData(true);
        const path = `${API_ROUTES.getFiltersData}?idClientMenu=${menuId}`
        
        try {
          const apiResponse = await fetchData(path, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }
          });
          setTimePeriod(apiResponse.data.ProductFilterType)
          setMainCategory(apiResponse.data.FullMenuData.MainCategories);
          setSubCategory(apiResponse.data.FullMenuData.SubCategories);
          setProducts(apiResponse.data.FullMenuData.Products);
          setTaxParentType(apiResponse.data.AllParentTaxPercents);
          setTaxType(apiResponse.data.AllTaxTypes)
          setExportOptions(apiResponse.data.ExportTypes)
          localStorage.setItem("exportOptions", JSON.stringify(apiResponse.data.ExportTypes))
          
        } catch (error) {
        } finally {
          // setIsLoadingLiveData(false);
        }
      };
      
      getData();
    }
  }, [menuId, location.pathname]);

  useEffect(() => {
    if (
       location.pathname === "/reports/soldProducts"
    ) {
      if (selectedLocation) {
        
        const getData = async () => {
          const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
          const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
          const requestBody = {
            IdMenu: selectedLocation,
            "TargetDate": formatDate(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
            "TargetDateTo": formatDate(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
          };


          try {
            const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            setBackendAllowedDates(apiResponse.data)
            setAllowedDatesFromPicker(apiResponse.data);
            setAllowedDatesToPicker(apiResponse.data);
          } catch (error) {

          } finally {
        
          }
        };

        getData();
      }
    }
  }, [selectedLocation, location.pathname]);

  useEffect(() => {
    if (
      location.pathname === "/reports/soldProducts"
   ) {
     if (selectedLocation) {
       
       const getData = async () => {
         const startOfMonth = new Date(currentFromMonth.getFullYear(), currentFromMonth.getMonth(), 1);
         const endOfMonth = new Date(currentFromMonth.getFullYear(), currentFromMonth.getMonth() + 1, 0);
         const requestBody = {
           IdMenu: selectedLocation,
           "TargetDate": formatDate(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
           "TargetDateTo": formatDate(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
         };


         try {
           const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
             method: "POST",
             headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${token}`,
             },
             data: requestBody,
           });
           setAllowedDatesFromPicker(apiResponse.data)
         } catch (error) {

         } finally {
       
         }
       };

       getData();
     }
   }
  },[currentFromMonth])

  useEffect(() => {
    if (
      location.pathname === "/reports/soldProducts"
   ) {
     if (selectedLocation) {
       
       const getData = async () => {
         const startOfMonth = new Date(currentToMonth.getFullYear(), currentToMonth.getMonth(), 1);
         const endOfMonth = new Date(currentToMonth.getFullYear(), currentToMonth.getMonth() + 1, 0);
         const requestBody = {
           IdMenu: selectedLocation,
           "TargetDate": formatDate(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
           "TargetDateTo": formatDate(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
         };


         try {
           const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
             method: "POST",
             headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${token}`,
             },
             data: requestBody,
           });
           setAllowedDatesToPicker(apiResponse.data)
         } catch (error) {

         } finally {
       
         }
       };

       getData();
     }
   }
  },[currentToMonth])

  const handleMonthChange = (date: Date) => {
    setCurrentMonth(date);
  };

  const handleFromMonthChange = (date: Date) => {
    setCurrentFromMonth(date)
  }
  const handleToMonthChange = (date: Date) => {
    setCurrentToMonth(date)
  }

  useEffect(() => {
    if(allowedDatesFromPicker) {
      const targetDates = allowedDatesFromPicker.map((date: any) => new Date(date.TargetDate));
      setAllowFrom(targetDates.sort((a:any, b:any) => b.getTime() - a.getTime()))
    }
  }, [allowedDatesFromPicker])

  useEffect(() => {
    if(allowedDatesToPicker) {
      const targetDates = allowedDatesToPicker.map((date: any) => new Date(date.TargetDate));
      setAllowTo(targetDates.sort((a:any, b:any) => b.getTime() - a.getTime()))
    }
  }, [allowedDatesToPicker])

  
  const handleSelectChange = (selectedOption: any) => {
    setSelectedLocation(selectedOption);
  };
  
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  
  const [allFilters, setAllFilters] = useState<any>({
    IdMenu: "",
    TargetDate: "",
    TargetDateTo: "",
    Language: "",
    FilterType: "1",
    HourFilterFrom: "0",
    HourFilterTo: "24",
    ReportType: "1",
    ExportType: "",
    User: "",
    MainCategoryIds: "",
    SubCategoryIds: "",
    ProductIds: "",
    TaxIds: ""
  });
  useEffect(() => {
    setAllFilters({
      IdMenu: menuId.toString(),
      TargetDate: fromDate,
      TargetDateTo: toDate,
      Language: i18n.resolvedLanguage,
      FilterType: selectedTimePeriod.toString(),
      HourFilterFrom: "0",
      HourFilterTo: "24",
      ReportType: "1",
      ExportType: +selectedExportType,
      User: "",
      MainCategoryIds: selectedMainCategory.toString(),
      SubCategoryIds: selectedSubCategory.toString(),
      ProductIds: selectedProducts.toString(),
      TaxIds: selectedTax.toString()
    })

  }, [menuId, fromDate, toDate, i18n, fromTime, toTime, selectedExportType, selectedMainCategory, selectedSubCategory, selectedTax, selectedTimePeriod])


  useEffect(() => {
    sendFiltersToParent(allFilters);
  }, [allFilters, sendFiltersToParent, ]);

  useEffect(() => {
    const getFirstAndLastDayOfMonth = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return { firstDay, lastDay };

      
    };
    
    const { firstDay, lastDay } = getFirstAndLastDayOfMonth();
  
    const isDateInCurrentMonth = (date: any) => {
      const d = new Date(date);
      return d >= firstDay && d <= lastDay;
    };
    const currentMonthAllowedDates = allowedDates.filter(isDateInCurrentMonth);
  
    if (currentMonthAllowedDates.length > 0) {
      setFromDate(formatDate(currentMonthAllowedDates[currentMonthAllowedDates.length - 1]));
      setToDate(formatDate(currentMonthAllowedDates[0]));
    }
  }, [allowedDates]);
  
  useEffect(() => {
    if (selectedMainCategory.length > 0) {
      const filteredSubCategories = subCategory.filter((category: any) =>
        selectedMainCategory.includes(category.IdParent) || category.IdParent == 0
    );
    
    const mappedSubCategories = filteredSubCategories.map((category: any) => ({
      value: category.Id,
      label: category.Description
    }));
    
    setSubCategoryOptions([
      ...mappedSubCategories
    ]);
  } else {
    // If no main category is selected, show only "all"
    setSubCategoryOptions([{ value: subCategoryOptions && subCategoryOptions[0].value, label: subCategoryOptions && subCategoryOptions[0].label }]);
  }
}, [selectedMainCategory, subCategory, t]);

useEffect(() => {
  if (initialRender.current && allowedDates.length > 0) {
    setFromDate(formatDate(allowedDates[allowedDates.length - 1]));
    setToDate(formatDate(allowedDates[0]));
    initialRender.current = false;
  }
}, [allowedDates]);

useEffect(() => {
  if(selectedSubCategory.length > 0) {
    if(products.length > 0){
      
      
      const filteredProduct = products.filter(
        (product: any) => selectedSubCategory.includes(parseInt(product.IdParent)) || product.IdParent == 0
      );
      
      const mappedProducts = filteredProduct.map((product: any) => ({
        value: product.Id,
        label: product.Description
      }));
      setProductsOptions([
        ...mappedProducts])
      }
    }else{
      setProductsOptions([{ value: products && products[0].Id, label: products && products[0].Description }])
    }
  }, [selectedSubCategory, products])
  
  const handleSubCategoryChange = (selectedOption: any) => {
    setSelectedSubCategory(selectedOption);
  }
  
  const timePeriodOptions = timePeriod.map((period: any) => ({
    value: period.Id,
    label: period.Description
  }));
  const mainCategoryOptions = mainCategory.map((category: any) => ({
    value: category.Id,
    label: category.Description
  }));
  
  const taxParentTypeOptions = taxParentType.map((category: any) => ({
    value: category.Percentage,
    label: category.VatName
  }));
  

  const taxTypeOptions = taxType.map((category: any) => ({
    id: category.Percentage,
    value: category.Id,
    label: category.VatName
  }));

  const exportTypeOptions = exportOptions
  .map((type: any) => ({
    value: type.Id,
    label: type.Description
  }))
  // .filter((option: any) => option.label !== "CSV");



  const handleMainCategoryChange = (selectedOption: any) => {
    if (selectedOption.some((option: any) => option.value === 0)) {
      setSelectedMainCategory([0]);
    } else {
      setSelectedMainCategory(selectedOption);
    }
  };

  const handleProductsChange = (product: any) => {
    if (product.some((option: any) => option.value === 0)) {
      setSelectedProducts([0]);
    } else {
      setSelectedProducts(product)
    }
    setSelectedProducts(product)
  }


  const handleParentTaxChange = (parentTax: any) => {
    const matchedOptions = taxTypeOptions.filter((option: any) => parentTax.includes(option.id));

    if (matchedOptions.length > 0) {
      const matchedValues = matchedOptions.map((option: any) => option.value);
      setSelectedTaxParent(matchedValues);
      setSelectedTax(matchedValues)
    }
  };

  const handleTaxChange = (tax: any) => {
    setSelectedTax(tax)
  }

  // const handleExportChange = (exportType: any) => {
  //   setSelectedExportType(exportType)
  // }


  const handleTimePeriodChange = (option: any) => {
    setSelectedTimePeriod(option)
  }

  const [isMobile, setIsMobile] = useState<boolean>();

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /iphone|ipad|ipod|android/.test(userAgent);
    const isSmallScreen = window.innerWidth <= 768;
  
    setIsMobile(isMobileDevice && isSmallScreen);
  }, []);

    useEffect(() => {
      if (backendAllowedDates) {
        const targetDates = backendAllowedDates.map((date: any) => new Date(date.TargetDate));
        setAllowedDates(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
      }
    }, [backendAllowedDates]);

    useEffect(() => {
      if (allowedDates.length > 0) {
        setFromDate(formatDate(allowedDates[allowedDates.length - 1]));
        setToDate(formatDate(allowedDates[0]));
      }
    }, [allowedDates]);

  // const disabledDate = (current: any) => {
  //   const currentDate = current.toDate(); // Convert dayjs object to Date
  //   return !allowedDates.some(allowedDate => 
  //     allowedDate.toDateString() === currentDate.toDateString() // Compare as strings
  //   );
  // };

  const disabledDate = (current: any) => {
    const currentDate = current.toDate(); // Convert dayjs object to Date
    return !allowedFrom.some((allowedFrom: any) => 
      allowedFrom.toDateString() === currentDate.toDateString() // Compare as strings
    );
  };
  const disabledToDate = (current: any) => {
    const currentDate = current.toDate(); // Convert dayjs object to Date
    return !allowedTo.some((allowedTo: any) => 
      allowedTo.toDateString() === currentDate.toDateString() // Compare as strings
    );
  };

  return (
     <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-24 mt-1 flex flex-col items-center justify-center gap-4 border-b pb-8 md:pb-12">
      <div className="w-full flex flex-col md:flex-row items-center justify-center">


        <div className="w-full flex flex-col">

          <div className="w-full">
          <Heading
            align="left"
            children={t("soldProducts.title")}
            classes="pt-16 md:pt-0 mt-1 min-w-full md:text-4xl"
            color=""
            size="h1"
          />
          <Text
            align=""
            children={t("soldProducts.subTitle")}
            classes="font-thin"
            color="text-custom-textGraySec"
            size=""
          />
        </div>

        <Select
          classes="md:hidden flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-4"
          options={options}
          onChange={handleSelectChange}
          defaultValue={options[0]}
          />
          </div>
        <div className="w-full md:w-1/2 mt-4 flex gap-4">

        <div className="text-left w-1/2 ">
          <p className="mb-2">{t("periodReport.from")}</p>
          <div className="flex flex-col h-14 justify-between">
            <fieldset>
              {fromDate && 
        <DatePicker
        ref={ref}
        format="DD/MM/YYYY hh A"
        datatype="dd/mm/yyyy"
        defaultValue={fromDate && dayjs(`'${fromDate} 12 AM'`, 'DD/MM/YYYY hh A')}
        onChange={(date, dateString) => {
          // Ensure dateString is a string
          if (typeof dateString === 'string') {
            const [datePart, timePart] = dateString.split(' '); // Split the date and time
            if(timePart){
              
              const hour = parseInt(timePart.split(' ')[0], 10); // Get the hour from the time part
              const isPM = timePart.includes('PM');
              let hourIn24 = hour;
              
              if (isPM && hourIn24 < 12) {
                hourIn24 += 12; // Convert PM to 24-hour format
              } else if (!isPM && hourIn24 === 12) {
                hourIn24 = 0; // Convert 12 AM to 0
              }
              
              const formattedTime = hourIn24.toString().padStart(2, '0');
              setFromTime(formattedTime)
            }
            setFromDate(datePart)
            
          } else {
            console.error('dateString is not a string:', dateString);
          }
        }}
        showTime={{ use12Hours: false }}
        showHour={true}
        inputReadOnly 
        disabledDate={disabledDate}
        showNow={false}
        onPanelChange={(date: any, mode) => {
          if (mode === 'date') {
            handleFromMonthChange(date.toDate());
          }
        }}
        placeholder={t("datePicker.placeholder")}
        className="bg-custom-bgBlue text-white py-2 border-none"
        // onPickerValueChange={() => {console.log("test")}}
        />
      }
            </fieldset>
            
          </div>
        </div>

        <div className="text-left w-1/2">
          <p className="mb-2">{t("periodReport.to")}</p>
          <div className="flex flex-col h-14 justify-between">
      
          <fieldset>
            {toDate && 
        <DatePicker
        ref={ref}
        format="DD/MM/YYYY hh A"
        datatype="dd/mm/yyyy"
        removeIcon={false}
        defaultValue={toDate && dayjs(`'${toDate} 11 AM'`, 'DD/MM/YYYY hh A')}
        onChange={(date, dateString) => {
          // Ensure dateString is a string
          if (typeof dateString === 'string') {
            const [datePart, timePart] = dateString.split(' '); // Split the date and time
            if(timePart){
              
              const hour = parseInt(timePart.split(' ')[0], 10); // Get the hour from the time part
              const isPM = timePart.includes('PM');
              let hourIn24 = hour;
              
              if (isPM && hourIn24 < 12) {
                hourIn24 += 12; // Convert PM to 24-hour format
              } else if (!isPM && hourIn24 === 12) {
                hourIn24 = 0; // Convert 12 AM to 0
              }
              
              const formattedTime = hourIn24.toString().padStart(2, '0');
              setToTime(formattedTime)
            }
            setToDate(datePart)
          } else {
            console.error('dateString is not a string:', dateString);
          }
        }}
        showTime={{ use12Hours: false }}
        showHour={true}
        inputReadOnly 
        disabledDate={disabledToDate}
        showNow={false}
        onPanelChange={(date: any, mode) => {
          if (mode === 'date') {
            handleToMonthChange(date.toDate());
          }
        }}
        placeholder={t("datePicker.placeholder")}
        className="bg-custom-bgBlue text-white py-2 border-none"
        />
      }
          </fieldset>
          </div>
        </div>
        </div>
        </div>
        
        <div className="w-full grid grid-cols-1 gap-4">
          <span className="flex justify-between items-center">
            <p className=" w-full md:w-1/3">{t("soldProducts.timePeriod")}</p>
            <Dropdown isMultiSelect={false} onChange={handleTimePeriodChange} options={timePeriodOptions} title={timePeriodOptions.length > 0 && timePeriodOptions[1].label} />
          </span>
        </div>

        <div className="w-full grid grid-cols-1 gap-4">
        <span className="flex justify-between items-center">
            <p className=" w-full md:w-1/3">{t("soldProducts.mainCategory")}</p>
            <Dropdown isMultiSelect={true} onChange={handleMainCategoryChange} options={mainCategoryOptions} title={mainCategoryOptions.length > 0 && mainCategoryOptions[0].label}/>
          </span>
          <span className="flex justify-between items-center">
            <p className=" w-full md:w-1/3">{t("soldProducts.subCategory")}</p>
            {subCategoryOptions.length > 0 ? (<Dropdown isMultiSelect={true} onChange={handleSubCategoryChange} options={subCategoryOptions } title={subCategoryOptions && subCategoryOptions[0].label}/>
         ) : (<></>)}
             </span>
        </div>

        <div className="w-full">
        <span className="flex justify-between items-center">
            <p className=" w-full md:w-1/3">{t("soldProducts.products")}</p>
            {products.length > 0 ? (<Dropdown isMultiSelect={true} onChange={handleProductsChange} options={productsOptions} title={products && products[0].Description}/>
          ) : (<></>)}
            </span>
        </div>

        <div className="w-full grid grid-cols-1 gap-4">
          {isTaxParentGroup ? <span className="flex justify-between items-center">
            <p className=" w-full md:w-1/3">{t("soldProducts.taxParentType")}</p>
            <Dropdown isTaxDropdown={true} isMultiSelect={true} onChange={handleParentTaxChange} options={taxParentTypeOptions} title={taxParentTypeOptions.length > 0 && taxParentTypeOptions[0].label}/>
          </span> : <></>}
        
          <span className="flex justify-between items-center">
            <p className=" w-full md:w-1/3">{t("soldProducts.taxType")}</p>
            <Dropdown isTaxDropdown={true} isMultiSelect={true} onChange={handleTaxChange} options={taxTypeOptions} title={taxTypeOptions.length > 0 && taxTypeOptions[0].label}/>
          </span>
        </div>

          {/* <div className="w-full ">
            <span className="flex justify-between items-center">
            <p className=" w-full md:w-1/3">{t("soldProducts.exportAs")}</p>
            <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label}/>
            </span>
          </div>  */}
    </Wrapper >
  );
};

export default SoldProductsReportFilters;
