export enum AppRoutes {
  Home = "/",
  SignUp = "/signup",
  Login = "/login",
  ForgotPw = "/forgotpassword",
  AccountSettings = "/accountSettings",
  Reports = "/reports",
  ReportsDashboard = "/reports/dashboard",
  ReportsSales = "/reports/sales",
  ReportsZreport = "/reports/zreport",
  ReportsPeriodReport = "/reports/periodReport",
  SoldProducts = "/reports/soldProducts",
  PercentageReport = "/reports/percentageReport",
  MainCategory = "/reports/mainCategory",
  ReportsPerformance = "/reports/performance",
  ReportsMarketing = "/reports/marketing",
  ReportsProducts = "/reports/products",
  ReportsEmployees = "/reports/employees",
  ReportsAdministration = "/reports/administration",
  Settings = "/settings",
  Calendar = "/calendar",
  Register = "/register",
  Products = "/products",
  Marketing = "/marketing",
}
