import { useTranslation } from "react-i18next";
import Wrapper from "../../components/common/wrapper/Wrapper";
import ToggleElement from "../../components/common/toggleElement/ToggleElement";
import Heading from "../../components/common/heading/Heading";
import Text from "../../components/common/text/Text";
import { useContext, useState } from "react";
import { ThemeContext } from "../../context/themeContext";

function Settings() {

    const { t } = useTranslation();

    

    // const [darkMode, setDarkMode] = useState(false);
    // const { isDarkMode, toggleTheme, isLoggedIn } = useContext(ThemeContext);


    // const toggleDarkMode = () => {
    //   setDarkMode(!darkMode)
    //   console.log(window.matchMedia('(prefers-color-scheme: dark)').matches)
    // }
    // console.log(isDarkMode)

    // ${isDarkMode ? "dark" : ""}

  return (
    <Wrapper classes={`w-11/12 mx-auto  `} onClick={() => {}}>
      <Heading
            align="left"
            children={t("settings.title")}
            classes="pt-16 md:pt-8 min-w-full text-4xl"
            color=""
            size="h1"
          />
          <div className="w-full mt-10 border rounded-lg md:w-1/3">

        <div className="p-2 border-b">{t("settings.reportsSetting")}</div>
        <ToggleElement title={t("settings.useParentTaxGroup")} id="1" defaultValue={true}/>
        {/* <ToggleElement title={t("settings.darkMode")} id="2" defaultValue={false}/> */}
        {/* <button onClick={toggleTheme}>
          toggle dark mode
        </button> */}
          </div>
    </Wrapper>
  );
}

export default Settings;
