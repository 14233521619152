import React, { createContext, useState, ReactNode } from "react";
import { useCookies } from "react-cookie";

interface MenuItem {
  IdMenu: number;
  MenuName: string;
}

interface ReportsContextType {
  shouldRenderReports: boolean;
  setShouldRenderReports: React.Dispatch<React.SetStateAction<boolean>>;
  restaurantMenus: MenuItem[]; // Define restaurantMenus state
  setRestaurantMenus: React.Dispatch<React.SetStateAction<MenuItem[]>>; // Define setter for restaurantMenus
  menuId: any,
  setMenuId: any
}

export const PrivilegesContext = createContext<ReportsContextType>({
  shouldRenderReports: false,
  setShouldRenderReports: () => {},
  restaurantMenus: [],
  setRestaurantMenus: () => {}, // Initialize setter function
  menuId: null, 
  setMenuId: () => {}
});

export const PrivilegesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cookies] = useCookies(["isLogIn"]); // Include the cookie name you want to access

  // Access the value of the cookie
  const isLoggedIn = cookies.isLogIn;
  const [shouldRenderReports, setShouldRenderReports] =
    useState<boolean>(isLoggedIn);

  // State variable to store restaurant menus
  const [restaurantMenus, setRestaurantMenus] = useState<MenuItem[]>([]);
  const [menuId, setMenuId] = useState<any>();

  return (
    <PrivilegesContext.Provider
      value={{
        shouldRenderReports,
        setShouldRenderReports,
        restaurantMenus,
        setRestaurantMenus,
        menuId,
        setMenuId
      }}
    >
      {children}
    </PrivilegesContext.Provider>
  );
};
