import { useEffect, useState } from "react";
import Wrapper from "../../components/common/wrapper/Wrapper";
import LogInComponent from "../../components/logInComponent/LogInComponent";
import style from "./login.module.css";
import MobileLogInComponent from "../../components/logInComponent/MobileLoginComponent";

function LogIn() {
  const [isMobile, setIsMobile] = useState<boolean>();
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /iphone|ipad|ipod|android/.test(userAgent);
    const isSmallScreen = window.innerWidth <= 768;
  
    setIsMobile(isMobileDevice && isSmallScreen);
  }, []);
  return (
    <>
      <Wrapper
        children={isMobile ? <MobileLogInComponent /> : <LogInComponent />}
        classes={`h-screen w-full `}
        onClick={() => {}}
      />
    </>
  );
}

export default LogIn;
