import { Props } from "./ZReportRow.type";

const ZReportRow: React.FC<Props> = ({title, value, color, classes, fontSize, borderRadius}) => {

    const bgColor = color || "bg-custom-darkenTransparentGray";
    const fontS = fontSize || "";
    const borderR = borderRadius || "rounded-sm";
  return (
   <div className={`w-full text-custom-textBlackColor ${bgColor} flex justify-between p-1 px-2 ${borderR} ${classes}`}>
    <span className={` ${fontSize} ${bgColor === "bg-custom-bgPink" ? "font-bold" : ""}`}>
    {title}
    </span>

    <span className={`${fontSize} ${bgColor === "bg-custom-bgPink" ? "font-bold" : ""}`}>
    {value}
    </span>
   </div>
  );
};

export default ZReportRow;
