import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TopNavbar from "../../components/common/topNavbar/TopNavbar";
import { withTranslation } from "react-i18next";
import ReportsFilters from "../../components/reportsFilters/ReportsFilters";
import AnalyticComponent from "../../components/analyticComponent/AnalyticComponent";
import ChartsComponent from "../../components/chartsComponent/ChartsComponent";
import { useLocation, useNavigate } from "react-router-dom";
import Heading from "../../components/common/heading/Heading";
import Text from "../../components/common/text/Text";
import SalesFilters from "../../components/salesFilters/SalesFilters";
import SalesReports from "../../components/salesReports/SalesReports";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";  
import { API_ROUTES } from "../../config";
import useAxios from "../../hooks/useAxios";
import ZreportFilter from "../../components/zreportFilters/ZreportFilters";
import ZReports from "../../components/zReports/ZReports";
import PeriodReportFilter from "../../components/periodReportFilters/PeriodReportFilters";
import PeriodReports from "../../components/periodReports/PeriodReports";
import { getCookie } from "../../utilities/cookies";
import SoldProductsReportFilters from "../../components/soldProductsReportFilters/SoldProductsReportFilter";
import SoldProductsReports from "../../components/soldProductsReports/SoldProductsReports";
import PercentageReportFilters from "../../components/percentageReportFilters/PercentageReportFilters";
import PercentageReport from "../../components/percentageReport/PercentageReport";
import MainCategoryFilters from "../../components/mainCategoryFilters/MainCategoryFilters";
import MainCategoryReports from "../../components/mainCategoryReport/MainCategoryReport";
import MainCategoryReport from "../../components/mainCategoryReport/MainCategoryReport";
import { PrivilegesContext } from "../../context/privilegesContext";
import { ThemeContext } from "../../context/themeContext";


const Reports: React.FC = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState(location.pathname);
  const [liveData, setLiveData] = useState<any>();
  const [avgFoodSpendingData, setAvgFoodSpendingData] = useState([]);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [starsData, setStarsData] = useState([]);
  const [tablesAndSeatsData, setTablesAndSeatsData] = useState([]);
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  // const [menuId, setMenuId] = useState(localStorage.getItem("selectedLocation"));
  const token = getCookie("token");

  const [expiresDate, setExpiresDate] = useState(getCookie("expires_date"));
  const [selectedDate, setSelectedDate] = useState("");
  const [dateFrom, setDateFrom] = useState<any>();
  const [dateTo, setDateTo] = useState<any>();
  const navigate = useNavigate();
  // const { isDarkMode, toggleTheme, isLoggedIn } = useContext(ThemeContext);
  // console.log(isDarkMode)


  const [dateFromSoldProducts, setDateFromSoldProducts] = useState<any>();
  const [dateToSoldProducts, setDateToSoldProducts] = useState<any>();
  const [timeFromSoldProducts, setTimeFromSoldProducts] = useState<any>();
  const [timeToSoldProducts, setTimeToSoldProducts] = useState<any>();
  const [allFilters, setAllFilters] = useState<any>();
  const [allFiltersFromPercentageReport, setAllFiltersFromPercentageReport] = useState<any>();
  const [allFiltersFromMainCategoryReport, setAllFiltersFromMainCategoryReport] = useState<any>();
  const { menuId, setMenuId } = useContext(PrivilegesContext);

  const filterOptions = [
    // {
    //   value: "dashboard",
    //   label: t("reportsLinks.dashboard"),
    //   path: "/reports/dashboard",
    // },
    { value: "sales", label: t("reportsLinks.sales"), path: "/reports/sales" },
    {
      value: "z-report",
      label: t("reportsLinks.zreport"),
      path: "/reports/zreport",
    },
    {
      value: "period-report",
      label: t("reportsLinks.periodReport"),
      path: "/reports/periodReport"
    },
    {
      value: "sold-products",
      label: t("reportsLinks.soldProducts"),
      path: "/reports/soldProducts"
    },
    {
      value: "service-report",
      label: t("reportsLinks.percentageReport"),
      path: "/reports/percentageReport"
    },
    {
      value: "main-category",
      label: t("reportsLinks.mainCategory"),
      path: "/reports/mainCategory"
    }
  ];

  // const handleFilterChange = (value: string) => {
  //   setSelectedFilter(value);
  // };
    
  useEffect(() => {
    if (
      location.pathname === "/reports/dashboard" ||
      location.pathname === "/reports/sales"
    ) {
      if (menuId) {
        // checkTokenExpiration();
        const getData = async () => {
          const requestBody = {
            Language: i18n.language,
            IdMenu: menuId,
          };

          setIsLoadingLiveData(true);

          try {
            const apiResponse = await fetchData(API_ROUTES.getLiveData, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            setAvgFoodSpendingData(
              apiResponse.data.AdditionalInformation.CategoryTypesPercentages,
            );
            setStarsData(apiResponse.data.AdditionalInformation.FeedbackStars);
            setLiveData(apiResponse.data);
            setTablesAndSeatsData(apiResponse.data.AdditionalInformation);
            setPaymentData(apiResponse.data.Payments);
          } catch (error) {
            console.error("Error fetching data:", error);
            setAvgFoodSpendingData([]);
            setStarsData([]);
            setLiveData(null);
            setTablesAndSeatsData([]);
            setPaymentData([]);
          } finally {
            setIsLoadingLiveData(false);
          }
        };

        getData();
      }
    }
  }, [menuId, location.pathname]);

  const handleMenuIdFromSalesFilters = (selectedLocation: any) => {
    setMenuId(selectedLocation);
  };
  const handleDate = (selectedDate: any) => {
    setSelectedDate(selectedDate)
  }

  const handleFromDateFromPeriodFilters = (selectedDate: any) => {
    setDateFrom(selectedDate);
  }

  const handleToDateFromPeriodFilters = (selectedDate: any) => {
    setDateTo(selectedDate);
  }

  const handleFiltersFromSoldProducts = (filters: any) => {
    setAllFilters(filters)
  }
  const handleFiltersFromPercentageReport = (filters: any) => {
    setAllFiltersFromPercentageReport(filters)
  }
  const handleFiltersFromMainCategoryReport = (filters: any) => {
    setAllFiltersFromMainCategoryReport(filters)
  }

  return (
    <div className="Reports w-full bg-custom-bgGray">


      {location.pathname === "/reports/dashboard" ? (
        <>
          <ReportsFilters sendMenuIdToParent={handleMenuIdFromSalesFilters} />
          {liveData ? (
            <AnalyticComponent
              totalRevenue={liveData.CalculatedTotals.Forecast}
              turnoverPerGuest={liveData.CalculatedTotals.AvgPerPerson}
            />
          ) : (
            <AnalyticComponent totalRevenue={0} turnoverPerGuest={0}/>
          )}
          <ChartsComponent
            avgFoodSpendingData={avgFoodSpendingData}
            starsData={starsData}
            tablesAndSeatsData={tablesAndSeatsData}
          />
        </>
      ) : location.pathname === "/reports/sales" ? (
        <div className="w-full mx-auto overflow-x-hidden">
          {/* <div className="w-11/12 mx-auto md:pt-8">
            <Heading
              align="left"
              children={t("sales.title")}
              classes="pt-20 mt-1 min-w-full md:text-3xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("sales.subTitle")}
              classes="font-thin"
              color=""
              size=""
            />
          </div> */}
          {liveData ? (
            <SalesFilters
              sendMenuIdToParent={handleMenuIdFromSalesFilters}
              timeSinc={liveData.AdditionalInformation.TimeSync}
              dateSinc={liveData.AdditionalInformation.DateSync}
            />
          ) : (
            <SalesFilters
              sendMenuIdToParent={handleMenuIdFromSalesFilters}
              timeSinc="0"
              dateSinc="0"
            />
          )}
          {liveData ? (
            <SalesReports
              totalTurnoverValue={liveData.CalculatedTotals.TotalTurnover}
              pendingValue={liveData.CalculatedTotals.PendingTurnover}
              forecastValue={liveData.CalculatedTotals.Forecast}
              discountValue={liveData.CalculatedTotals.Discounts}
              occupiedValue={liveData.AdditionalInformation.TakenTablesCount}
              freeValue={liveData.AdditionalInformation.FreeTablesCount}
              avgBillValue={liveData.CalculatedTotals.AvgPerBill}
              avgPPValue={liveData.CalculatedTotals.AvgPerPerson}
              avgFoodSpendingData={avgFoodSpendingData}
              starsData={starsData}
              paymentData={paymentData}
            />
          ) : (
            <SalesReports
              totalTurnoverValue="0"
              pendingValue="0"
              forecastValue="0"
              discountValue="0"
              occupiedValue="0"
              freeValue="0"
              avgBillValue="0"
              avgPPValue="0"
              avgFoodSpendingData={avgFoodSpendingData}
              starsData={starsData}
              paymentData={paymentData}
            />
          )}
        </div>
      ) : location.pathname === "/reports/zreport" ? (
        <div className="w-full overflow-x-hidden">
          {/* <div className="w-11/12 mx-auto md:pt-8">
            <Heading
              align="left"
              children={t("zreport.title")}
              classes="pt-20 mt-1 min-w-full md:text-3xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("zreport.subTitle")}
              classes="font-thin"
              color=""
              size=""
            />
          </div> */}
          <ZreportFilter sendMenuIdToParent={handleMenuIdFromSalesFilters} sendDateToParent={handleDate}/>
          {menuId && <ZReports menuId={menuId} date={selectedDate} location=""/>}
        </div>
      ) : location.pathname === "/reports/periodReport" ? (
        <div className="w-full overflow-x-hidden">
          {/* <div className="w-11/12 mx-auto md:pt-8">
            <Heading
              align="left"
              children={t("periodReport.title")}
              classes="pt-16 min-w-full"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("periodReport.subTitle")}
              classes="font-thin"
              color=""
              size=""
            />
          </div> */}
          <PeriodReportFilter sendMenuIdToParent={handleMenuIdFromSalesFilters} sendFromDateToParent={handleFromDateFromPeriodFilters} sendToDateToParent={handleToDateFromPeriodFilters}/>
          {menuId && dateFrom && dateTo && <PeriodReports menuId={menuId} dateFrom={dateFrom} dateTo={dateTo}/>}
        </div>
      ) : location.pathname === "/reports/soldProducts" ? (
        <div className="w-full overflow-x-hidden">
        {/* <div className="w-11/12 mx-auto md:pt-8">
          <Heading
            align="left"
            children={t("soldProducts.title")}
            classes="pt-16 min-w-full"
            color=""
            size="h1"
          />
          <Text
            align=""
            children={t("soldProducts.subTitle")}
            classes="font-thin"
            color=""
            size=""
          />
        </div> */}
        <SoldProductsReportFilters sendFiltersToParent={handleFiltersFromSoldProducts}/>
        {<SoldProductsReports filters={allFilters}/>}
        
      </div>
      ) : location.pathname === "/reports/percentageReport" ? (
        <div className="w-full overflow-x-hidden">
        {/* <div className="w-11/12 mx-auto md:pt-8">
          <Heading
            align="left"
            children={t("percentageReport.title")}
            classes="pt-16 min-w-full"
            color=""
            size="h1"
          />
          <Text
            align=""
            children={t("percentageReport.subTitle")}
            classes="font-thin"
            color=""
            size=""
          />
        </div> */}
        <PercentageReportFilters sendFiltersToParent={handleFiltersFromPercentageReport}/>  
        <PercentageReport filters={allFiltersFromPercentageReport}/>
      </div>
    ) : location.pathname === "/reports/mainCategory" ? (
      <div className="w-full overflow-x-hidden">
      {/* <div className="w-11/12 mx-auto md:pt-8">
        <Heading
          align="left"
          children={t("mainCategory.title")}
          classes="pt-16 min-w-full"
          color=""
          size="h1"
        />
        <Text
          align=""
          children={t("mainCategory.subTitle")}
          classes="font-thin"
          color=""
          size=""
        />
      </div> */}
        <MainCategoryFilters sendFiltersToParent={handleFiltersFromMainCategoryReport} />
        <MainCategoryReport filters={allFiltersFromMainCategoryReport}/>
    </div>
  ) 
    : (<></>)
      }
    </div>
  );
};

export default withTranslation()(Reports);
