import React, { createContext, useState, ReactNode } from "react";

interface ReportsContextType {
  isLoadingLiveData: boolean;
  setIsLoadingLiveData: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoadingLiveDataContext = createContext<ReportsContextType>({
  isLoadingLiveData: false,
  setIsLoadingLiveData: () => {},
});

export const LoadingLiveDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoadingLiveData, setIsLoadingLiveData] = useState<boolean>(false);

  return (
    <LoadingLiveDataContext.Provider
      value={{ isLoadingLiveData, setIsLoadingLiveData }}
    >
      {children}
    </LoadingLiveDataContext.Provider>
  );
};
