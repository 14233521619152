import { useEffect, useRef, useState } from "react";
import Select from "../common/select/Select";
import Wrapper from "../common/wrapper/Wrapper";
import "react-datepicker/dist/react-datepicker.css";
import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import { ZreportFilterProps } from "./ZReportFilters.type";
import useDateFormatter from "../../hooks/useDateFormatter";
import { API_ROUTES } from "../../config";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import { DatePicker } from "antd";
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";

const ZreportFilter: React.FC<ZreportFilterProps> = ({
  sendMenuIdToParent,
  sendDateToParent
}) => {
  const { t } = useTranslation();
  const { formatDate } = useDateFormatter();
  const UserMenus = localStorage.getItem("UserMenus");
  const { fetchData } = useAxios();
  const location = useLocation();
  const [menuId, setMenuId] = useState();
  const [backendAllowedDates, setBackendAllowedDates] = useState<any>();
  const [allowedDates, setAllowedDates] = useState<Date[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const token = localStorage.getItem("token");
  const ref = useRef<any>();
  const [isMobile, setIsMobile] = useState<boolean>();

  useEffect(() => {
    if (backendAllowedDates) {
      const targetDates = backendAllowedDates.map((date: any) => new Date(date.TargetDate));
      setAllowedDates(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
    }
  }, [backendAllowedDates]);

  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;

  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });

  useEffect(() => {
    if (location.pathname === "/reports/zreport" && menuId) {
      const getData = async () => {
        const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        const requestBody = {
          IdMenu: menuId,
          "TargetDate": formatDate(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
          "TargetDateTo": formatDate(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
        };

        try {
          const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: requestBody,
          });
          setBackendAllowedDates(apiResponse.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getData();
    }
  }, [menuId, location.pathname, currentMonth]);

  const [selectedLocation, setSelectedLocation] = useState(options[0].value);
  const [fromDate, setFromDate] = useState<any>();

  useEffect(() => {
    if (allowedDates.length > 0) {
      setFromDate(allowedDates[0]);
    }
  }, [allowedDates]);

  const handleSelectChange = (selectedOption: any) => {
    setSelectedLocation(selectedOption);
  };

  useEffect(() => {
    if (fromDate) {
      sendDateToParent(formatDate(fromDate));
    }
    sendMenuIdToParent(selectedLocation);
    setMenuId(selectedLocation);
  }, [selectedLocation, fromDate]);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const futureDatesExist = allowedDates.some(date => date > fromDate);
  const pastDatesExist = allowedDates.some(date => date < fromDate);

  const goToPreviousDate = () => {
    const previousDate = allowedDates
      .filter(date => date < fromDate)
      .sort((a: any, b: any) => b - a)[0];
    setFromDate(previousDate || fromDate);
  };

  const goToNextDate = () => {
    const nextDate = allowedDates
      .filter(date => date > fromDate)
      .sort((a: any, b: any) => a - b)[0];
    setFromDate(nextDate || fromDate);
  };

  const handleMonthChange = (date: Date) => {
    setCurrentMonth(date);
  };

  const disabledDate = (current: any) => {
    const currentDate = current.toDate(); // Convert dayjs object to Date
    return !allowedDates.some(allowedDate => 
      allowedDate.toDateString() === currentDate.toDateString() // Compare as strings
    );
  };

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /iphone|ipad|ipod|android/.test(userAgent);
    const isSmallScreen = window.innerWidth <= 768;
  
    setIsMobile(isMobileDevice && isSmallScreen);
  }, []);

  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-28 flex flex-col md:flex-row items-center justify-center md:mb-8">
    
    <div className="w-full">
            <Heading
              align="left"
              children={t("zreport.title")}
              classes="pt-16 md:pt-0 min-w-full md:text-4xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("zreport.subTitle")}
              classes="font-thin"
              color="text-custom-textGraySec"
              size=""
            />
          </div>
    
      <div className="w-full md:hidden">
        <Select
          classes="flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-4"
          options={options}
          onChange={handleSelectChange}
          defaultValue={options[0]}
        />
        </div>

          <div className="flex flex-col h-fit w-full md:w-1/3 mt-4">
            <p className="text-base font-semibold mb-1">{t("sales.dateAndTime")}</p>
            <fieldset>
              {isMobile && fromDate ? <DatePicker
                ref={ref}
                format="DD/MM/YYYY hh A"
                datatype="dd/mm/yyyy"
                defaultValue={dayjs(fromDate).hour(0).minute(0)} // Ensure correct default value
                onChange={(date, dateString) => {
                  if (date) {
                    setFromDate(date.toDate()); // Use dayjs toDate() method
                  }
                }}
                showTime={{ use12Hours: true }}
                inputReadOnly 
                disabledDate={disabledDate}
                showNow={false}
                picker="date"
                onPanelChange={(date: any, mode: any) => {
                  if (mode === 'date') {
                    handleMonthChange(date.toDate());
                  }
                }}
                placeholder={t("datePicker.placeholder")}
                className="bg-custom-bgBlue py-2 border-none w-full custom-datepicker"
              /> : fromDate && <DatePicker
                ref={ref}
                format="DD/MM/YYYY hh A"
                datatype="dd/mm/yyyy"
                defaultValue={dayjs(fromDate).hour(0).minute(0)} // Ensure correct default value
                onChange={(date, dateString) => {
                  if (date) {
                    setFromDate(date.toDate()); // Use dayjs toDate() method
                  }
                }}
                showTime={{ use12Hours: true }}
                inputReadOnly 
                disabledDate={disabledDate}
                showNow={false}
                picker="date"
                onPanelChange={(date: any, mode: any) => {
                  if (mode === 'date') {
                    handleMonthChange(date.toDate());
                  }
                }}
                placeholder={t("datePicker.placeholder")}
                className="bg-custom-bgBlue py-2 border-none w-full custom-datepicker"
                popupClassName=""
              />}
             
            </fieldset>
          </div>
    </Wrapper>
  );
};

export default ZreportFilter;
