import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Heading from "../common/heading/Heading";
import { Doughnut } from "react-chartjs-2";
import { Props } from "./RestaurantOccupationChart.type";
import { useEffect, useState } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

const RestaurantOccupationChart: React.FC<Props> = ({
  FreeTablesCount,
  TakenTablesCount,
  Seats,
  OccupiedSeats,
}) => {
  const { t } = useTranslation();
  const [showChart, setShowChart] = useState<boolean>();



  const backendData = [
    {
      Label: "Free Seats",
      Amount: Seats,
    },
    {
      Label: "Occupied Seats",
      Amount: OccupiedSeats,
    },
    {
      Label: "Free Tables",
      Amount: FreeTablesCount,
    },
    {
      Label: "Taken Tables",
      Amount: TakenTablesCount,
    },
  ];

  useEffect(()=>{
    if(FreeTablesCount && TakenTablesCount && Seats && OccupiedSeats){
      setShowChart(true)
    }else{
      setShowChart(false)
    }
  },[backendData]);

  const labels1 = backendData.map((item) => item.Label);
  const amounts1 = backendData.map((item) => item.Amount);
  const selectedElements = amounts1.slice(0, 2);

  const totalAmount1 = amounts1.reduce((acc, cur) => acc + cur, 0);

  const percentages1 = amounts1.map((amount) =>
    ((amount / totalAmount1) * 100).toFixed(2),
  );

  const data = {
    labels: labels1,
    datasets: [
      {
        label: "",
        data: selectedElements,
        backgroundColor: ["#57A1A6", "#DAE883", "#f0f0f0", "#e0e0e0"],
        borderColor: ["#57A1A6", "#DAE883", "#f0f0f0", "#e0e0e0"],
      },
      {
        label: "", // Add a label for the second dataset if needed
        data: [FreeTablesCount, TakenTablesCount],
        backgroundColor: ["#f0f0f0", "#e0e0e0", "#d0d0d0"],
        borderColor: ["#f0f0f0", "#e0e0e0", "#d0d0d0"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "right" as const,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.label || "";
            if (label) {
              label += ": ";
            }
            const value = context.formattedValue;
            const percent =
              context.datasetIndex === 0 ? percentages1[context.dataIndex] : ""; // Percentage only for the first set
            return `${label}${value} (${percent}%)`;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    responsive: true,
    radius: "100%", // adjust the radius of the circle
    animation: {
      duration: 1000,
    },
  };

  return (
    <Wrapper
      onClick={() => {}}
      classes="bg-white rounded-xl p-6 min-w-full flex flex-col border pb-0 shadow-xl"
    >
      <div>
        <Heading
          children={t("restaurantOccupationChart.title")}
          align=""
          classes="black text-left"
          color=""
          size="h1"
        />
      {!showChart ? (
          <p className="pb-4">{t("errorMessages.noDataAvailable")}</p>
        ) : (
          <div
            style={{
              position: "relative",
              width: "300px",
              height: "300px",
              borderRadius: "100%",
            }}
          >
            <Doughnut data={data} options={options}></Doughnut>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default RestaurantOccupationChart;
