const GuestIcon = () => {
  const svgIcon = `
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="4" y="4" width="48" height="48" rx="24" fill="#F4EBFF"/>
  <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8"/>
  <path d="M19 36C21.3358 33.5226 24.507 32 28 32C31.493 32 34.6642 33.5226 37 36M32.5 23.5C32.5 25.9853 30.4853 28 28 28C25.5147 28 23.5 25.9853 23.5 23.5C23.5 21.0147 25.5147 19 28 19C30.4853 19 32.5 21.0147 32.5 23.5Z" stroke="#7F56D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
         
      `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default GuestIcon;
