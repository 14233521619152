import Wrapper from "../common/wrapper/Wrapper";
import { useContext, useEffect, useState } from "react";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SoldProducts, SoldProductsProps } from "./SoldProductsReports.type";
import SoldProductsTable from "../soldProductsTable/SoldProductsTable";
import ArrowUpIcon from "../icons/ArrowUpIcon";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import Dropdown from "../common/dropdown/Dropdown";
import { createColumnHelper, getCoreRowModel, getPaginationRowModel } from "@tanstack/react-table";
import { groupData, transformGroupedDetails } from "../../utilities/soldProductsUtils";


type SoldProduct = {
  Year: number;
  Date: string;
  Month: number;
  MonthName: string | null;
  Time: string | null;
  WeekDay: number;
  WeekDayName: string | null;
  MainCategory: string;
  SubCategory: string;
  ProductName: string;
  TaxName: string;
  Quantity: number;
  SubTotal: number;
  Total: number;
  TaxExcludedWithDiscount: number;
  User: string;
  DIsplayOrder: number;
  ProductType: number;
  IsPromotion: boolean;
  IdStatus: number;
  IsWithDiscount: boolean;
  Discount: number;
  isSubTotal: boolean;
  isMainTotal: boolean;
};



const SoldProductsReports: React.FC<SoldProductsProps> = ({filters}) => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const {t} = useTranslation();
  const [soldProducts, setSoldProducts] = useState<SoldProducts[]>([])
  const [flattenedProducts, setFlattenedProducts] = useState<SoldProducts[]>([]);
  const [products, setProducts] = useState<SoldProducts[]>([])
  const [couverts, setCouverts] = useState<SoldProducts[]>([])
  const [outOfTurnoverProducts, setOutOfTurnoverProducts] = useState<SoldProducts[]>([])
  const [openSection, setOpenSection] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState<null | string>(null);
  const [exportType, setExportType] = useState<any>("0");
  const exportTypeOptions = [ 
    {value: '0', label: 'NO EXPORT'},
    {value: '1', label: 'PDF'},
    {value: '2', label: 'CSV'}];
  
  // const productsTH = [
  //   {id: 1, title: t("soldProducts.product")},
  //   {id: 2, title: t("soldProducts.amt")},
  //   {id: 3, title: t("soldProducts.taxExcl")},
  //   {id: 4, title: t("soldProducts.taxIncl")},
  //   {id: 5, title: t("soldProducts.taxInclInclBtw")}
  // ];

  const columnHelper = createColumnHelper<SoldProduct>()

  const productsTH = [
    columnHelper.accessor('ProductName', {
      header: t("soldProducts.product"),
    }),
    columnHelper.accessor('Quantity', {
      header: t("soldProducts.amt"),
    }),
    columnHelper.accessor('SubTotal', {
      header: t("soldProducts.taxExcl"),
      cell: (info) => info.getValue().toFixed(2),
    }),
    columnHelper.accessor('TaxExcludedWithDiscount', {
      header: t("soldProducts.taxIncl"),
      cell: (info) => info.getValue().toFixed(2),
    }),
    columnHelper.accessor('Total', {
      header: t("soldProducts.taxInclInclBtw"),
      cell: (info) => info.getValue().toFixed(2),
    }),
  ];

  const updateFiltersWithExportType = (filters: any, exportType: any) => {
    return {
      ...filters,
      ExportType: exportType,  // Update the ExportType field
    };
  };
  
  const getDataBe = () => {
    if (
      location.pathname === "/reports/soldProducts"
    ) {
      const updatedFilters = updateFiltersWithExportType(filters, exportType);

      if (filters) {
        const getData = async () => {
          const requestBody = updatedFilters;
          
          setIsLoadingLiveData(true);
          
          try {
            const apiResponse = await fetchData(API_ROUTES.getSoldProducts, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            setSoldProducts(apiResponse.data.SoldProducts)
            setFileUrl(apiResponse.data.DocumentUrl.FileName)
          } catch (error) {
          } finally {
            setIsLoadingLiveData(false);
          }
        };
        
        getData();
      }
    }
  }

  useEffect(() => {
    if (soldProducts.length > 0) {
      const grouped = soldProducts.reduce((acc: any, product: any) => {
        const { MainCategory, SubCategory, ProductType } = product;
  
        // Only include products with ProductType 0
        if (ProductType === 0) {
          if (!acc[MainCategory]) {
            acc[MainCategory] = {};
          }
          if (!acc[MainCategory][SubCategory]) {
            acc[MainCategory][SubCategory] = [];
          }
  
          acc[MainCategory][SubCategory].push(product);
        }
        
        return acc;
      }, {});
  
      const flattened: SoldProducts[] = [];
      for (const mainCategory in grouped) {
        for (const subCategory in grouped[mainCategory]) {
          grouped[mainCategory][subCategory].forEach((product: SoldProducts) => {
            flattened.push({
              ...product,
              MainCategory: mainCategory,
              SubCategory: subCategory,
            });
          });
        }
      }
      setFlattenedProducts(flattened);
    }
  }, [soldProducts]);
  

  useEffect(() => {
    if (soldProducts.length > 0) {
      const productsArray: SoldProducts[] = [];
      const couvertsArray: SoldProducts[] = [];
      const outOfTurnoverProductsArray: SoldProducts[] = [];
      soldProducts.forEach((product: SoldProducts) => {
        if (product.ProductType == 0) {
          productsArray.push(product);
        } else if (product.ProductType == 1) {
          couvertsArray.push(product);
        } else if (product.ProductType == 3) {
          outOfTurnoverProductsArray.push(product);
        }
      });
  
      setProducts(productsArray);
      setCouverts(couvertsArray);
      setOutOfTurnoverProducts(outOfTurnoverProductsArray);
    }
  }, [soldProducts])

  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };
  
  const groupedDetails = groupData(flattenedProducts);
  const groupedCouverts = groupData(couverts);
  const groupedOOT = groupData(outOfTurnoverProducts);

  const transformedDetails = transformGroupedDetails(groupedDetails, 0, t);
  const transformedCouverts = transformGroupedDetails(groupedCouverts, 1, t);
  const transformedOOT = transformGroupedDetails(groupedOOT, 3, t);

  
  const options = {
    data: transformedCouverts,
  columns: productsTH,
  getCoreRowModel: getCoreRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  initialState: {
    pagination: {
      pageIndex: 0, //custom initial page index
      pageSize: 10, //custom default page size
    },
  },
}

const options2 = {
  data: transformedDetails,
columns: productsTH,
getCoreRowModel: getCoreRowModel(),
getPaginationRowModel: getPaginationRowModel(),
initialState: {
  pagination: {
    pageIndex: 0, //custom initial page index
    pageSize: 10, //custom default page size
  },
},
}
const options3 = {
  data: transformedOOT,
columns: productsTH,
getCoreRowModel: getCoreRowModel(),
getPaginationRowModel: getPaginationRowModel(),
initialState: {
  pagination: {
    pageIndex: 0, //custom initial page index
    pageSize: 10, //custom default page size
  },
},
}

  const handleExportChange = (exportType: any) => {
    setExportType(exportType[0])
  }


  return (
    <Wrapper onClick={() => {}} classes={`w-[95%] mx-auto flex flex-col items-center pb-16 min-h-screen`}>
      
      <div className="md:hidden mb-8 w-full mt-8">
        <div className="w-full flex gap-4 mb-4">
            <span className={` ${!fileUrl ? "w-full" : "w-1/2"}`}>
              <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="border-custom-bgBlue text-custom-bgBlue" iconColor="#0DB5D6"/>
            </span>
            {/* {fileUrl &&  <span className="w-1/2 md:w-1/5">
              <button className="w-full border rounded-lg px-4 py-2 border-custom-bgBlue text-custom-bgBlue" onClick={() => window.open(fileUrl, "_blank")}>{t("periodReport.export")}</button>
            </span>} */}
            {fileUrl && (
        <button
        onClick={() => window.open(fileUrl, "_blank")}
        className="w-1/2 md:w-1/4  p-2 rounded-lg text-center flex justify-center border text-custom-bgBlue border-custom-bgBlue"
        >
        {t("soldProducts.download")}
      </button>
    )}
        </div>
            <span className="md:w-1/5">
              <button className="w-full rounded-lg px-4 py-2 bg-custom-bgBlue text-white" onClick={getDataBe}>{t("soldProducts.generate")}</button>
            </span>
      </div>
      <div className="hidden md:flex justify-between mb-12 w-full mt-4">
            <span className="w-1/7">
              <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="border-custom-bgBlue text-custom-bgBlue" iconColor="#0DB5D6"/>
            </span>

            <div className="flex w-1/3 gap-4">
              {fileUrl && <span className="w-full">
              <button className="w-full border rounded-lg px-4 py-2 border-custom-bgBlue text-custom-bgBlue" onClick={() => window.open(fileUrl, "_blank")}>{t("soldProducts.download")}</button>
            </span>}
  
            <span className="w-full">
              <button className="w-full rounded-lg px-4 py-2 bg-custom-bgBlue text-white" onClick={getDataBe}>{t("periodReport.generate")}</button>
            </span>
            </div>
      </div>

      {couverts.length > 0 ? (<div onClick={() => toggleSection("couverts")} className="flex justify-between items-center px-4 rounded-lg border border-gray-200 w-full relative my-2 mb-0 py-4 bg-white shadow-custom3">{t("soldProducts.couverts")} {openSection === "couverts" ? (<ArrowUpIcon color="black"/>) : (<ArrowDownIcon color="black"/>)} </div>) : (<></>)}
      {couverts.length > 0 && openSection === "couverts" ? <SoldProductsTable options={options}  details={couverts}/> : <></>}  

      {products.length > 0 ? (<div onClick={() => toggleSection("days")} className="flex justify-between items-center px-4 rounded-lg border border-gray-200 w-full relative my-2 mb-0 py-4 mt-4 bg-white shadow-custom3">{t("soldProducts.products")} {openSection === "days" ? (<ArrowUpIcon color="black"/>) : (<ArrowDownIcon color="black"/>)} </div>) : (<></>)}
      {products.length > 0 && openSection === "days" ? <SoldProductsTable options={options2}  details={flattenedProducts}/> : <></>}
    
      {outOfTurnoverProducts.length > 0 ? (<div onClick={() => toggleSection("outOfTurnover")} className="flex justify-between items-center px-4 rounded-lg border border-custom-bgOrange w-full relative my-2 mb-0 py-4 mt-4 bg-custom-bgOrange text-white shadow-custom3">{t("soldProducts.outOfTurnover")} {openSection === "outOfTurnover" ? (<ArrowUpIcon color="white"/>) : (<ArrowDownIcon color="white"/>)} </div>) : (<></>)}
      {outOfTurnoverProducts.length > 0 && openSection === "outOfTurnover" ? <SoldProductsTable options={options3}  details={outOfTurnoverProducts} borderColor="border-custom-bgOrange" totalColor="bg-custom-bgOrange" /> : <></>}  
    
      <ScrollToTopButton />
    </Wrapper>
  );
};

export default SoldProductsReports;
