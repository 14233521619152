import AvarageFoodSpendingChart from "../avarageFoodSpendingChart/AvarageFoodSpendingChart";
import Wrapper from "../common/wrapper/Wrapper";
import FeedbackAnalysis from "../feedbackAnalysis/FeedbackAnalysis";
import FoodGrading from "../foodGrading/FoodGrading";
import OrderVolumeChart from "../orderVolumeChart/OrderVolumeChart";
import RestaurantOccupationChart from "../restaurantOccupationChart/RestaurantOccupationChart";
import { useState } from "react";

interface ChartsProps {
  avgFoodSpendingData: any;
  starsData: any;
  tablesAndSeatsData: any;
}

const ChartsComponent: React.FC<ChartsProps> = ({
  avgFoodSpendingData,
  starsData,
  tablesAndSeatsData,
}) => {
  return (
    <Wrapper
      onClick={() => {}}
      classes="flex flex-row w-11/12 mx-auto mt-8 flex-wrap box-border justify-between gap-4 pb-10"
    >
      <div className="flex" style={{ width: "70.8%" }}>
        <OrderVolumeChart />
      </div>
      <div className="flex" style={{ width: "27.5%" }}>
        <RestaurantOccupationChart
          FreeTablesCount={tablesAndSeatsData.FreeTablesCount}
          TakenTablesCount={tablesAndSeatsData.TakenTablesCount}
          Seats={tablesAndSeatsData.Seats}
          OccupiedSeats={tablesAndSeatsData.OccupiedSeats}
        />
      </div>
      <div className="flex flex-col gap-4" style={{ width: "30%" }}>
        <FeedbackAnalysis data={starsData} />
        <AvarageFoodSpendingChart backendData={avgFoodSpendingData} />
      </div>
      <div className="" style={{ width: "68.5%" }}>
        <FoodGrading />
      </div>
    </Wrapper>
  );
};

export default ChartsComponent;
