import { useEffect, useState } from "react";

// Define a type for the labels
type Labels = string[];

const useChartLabels = (interval: string): Labels => {
  const [labels, setLabels] = useState<Labels>([]);

  useEffect(() => {
    const generateLabels = (): Labels => {
      const currentDate = new Date();

      switch (interval) {
        case "hour": {
          const currentHour = currentDate.getHours();
          const hourLabels: string[] = [];
          for (let i = 0; i < 12; i++) {
            const hour = (currentHour - i + 24) % 24; // Ensure hour is within 0-23 range
            hourLabels.unshift(`${hour}:00`);
          }
          return hourLabels;
        }
        case "day": {
          const daysOfWeek: string[] = [
            "Sun",
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat",
          ];
          const currentDayIndex = currentDate.getDay(); // 0 for Sunday, 1 for Monday, etc.
          return daysOfWeek
            .slice(currentDayIndex)
            .concat(daysOfWeek.slice(0, currentDayIndex));
        }
        case "week":
          return ["Week 1", "Week 2", "Week 3", "Week 4"]; // Example week labels
        default:
          return [];
      }
    };

    setLabels(generateLabels());
  }, [interval]); // Run whenever interval changes

  return labels;
};

export default useChartLabels;
