// config.ts

const API_BASE_URL = "https://my.tabletmenukaart.nl/";

export const API_ROUTES = {
  getToken: `${API_BASE_URL}/token`,
  getUserPrivileges: `${API_BASE_URL}/cms/getuserprivileges?username=`,
  getLiveData: `${API_BASE_URL}/cms/getlivedata`,
  getZreport: `${API_BASE_URL}/cms/getzreport`,
  getPeriodReport: `${API_BASE_URL}/cms/getperiodreport`,
  getAvaliableDates: `${API_BASE_URL}/cms/getavaliabledates`,
  getFiltersData: `${API_BASE_URL}/cms/getfiltersdata`,
  getSoldProducts: `${API_BASE_URL}/cms/getsoldproducts`,
  getPercentagesReport: `${API_BASE_URL}/cms/getpercentagesreport`
  // Add more API routes as needed
};
