import { useTranslation } from "react-i18next";
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import Wrapper from "../common/wrapper/Wrapper";
import GreenArrowUpIcon from "../icons/GreenArrowUpIcon";
import RedArrowDownIcon from "../icons/RedArrowDownIcon";
import { AnalyticCardProps } from "./AnalyticCard.type";

const AnalyticCard: React.FC<AnalyticCardProps> = ({
  value,
  title,
  percentage,
  icon,
}) => {
  const { t } = useTranslation();
  const colorClass = percentage.startsWith("+")
    ? "text-green-600 font-medium"
    : "text-red-600 font-medium";

  const ArrowIcon = percentage.startsWith("+")
    ? GreenArrowUpIcon
    : RedArrowDownIcon;

  return (
    <Wrapper
      onClick={() => {}}
      classes="bg-white w-full  rounded-xl flex flex-col px-4 py-4 items-start justify-evenly border"
    >
      <div className="mr-2 flex items-center mb-4">
        {icon}{" "}
        <Text
          children={title}
          align=""
          classes="ml-5 text-xl"
          color=""
          size=""
        />
      </div>
      <div className="flex flex-col">
        <span className="flex mb-4">
          <Heading
            children={value}
            classes="text-3xl"
            color=""
            size="h1"
            align=""
          />
        </span>
        <span className="flex items-center">
          <ArrowIcon />
          <Text align="" classes="" size="" color="">
            <span className={colorClass}>{percentage} </span>
            <span style={{ color: "gray" }}>
              {t("analyticComponent.vsLastMonth")}
            </span>
          </Text>
        </span>
      </div>
    </Wrapper>
  );
};

export default AnalyticCard;
